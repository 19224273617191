
export enum EducatorClassActionTypes {
    CLASS_MODE = '@@class/CLASS_MODE',
    SET_CLASS_REQUEST = '@@class/SET_CLASS_REQUEST',
    CLASS_ADD_REQUEST = '@@class/CLASS_ADD_REQUEST',
    CLASS_EDIT_REQUEST = '@@class/CLASS_EDIT_REQUEST',
    CLASS_REQUEST = '@@class/CLASS_REQUEST',
    CLASS_SUCCESS = '@@class/CLASS_SUCCESS',
    CLEAR_CLASS_DATA_REQUEST = '@@class/CLEAR_CLASS_DATA_REQUEST',
    CLASS_ERROR = '@@class/CLASS_ERROR',

    TEST_MODE = '@@class/TEST_MODE',
    TEST_SUCCESS = '@@class/TEST_SUCCESS',
    TEST_ERROR = '@@class/TEST_ERROR',
    SET_TEST_REQUEST = '@@class/SET_TEST_REQUEST',
    CLEAR_TEST_DATA_REQUEST = '@@class/CLEAR_TEST_DATA_REQUEST',

    CLASS_HISTORY_SUCCESS = '@@class/CLASS_HISTORY_SUCCESS',
    CLASS_HISTORY_ERROR = '@@class/CLASS_HISTORY_ERROR',

    SET_CLASS_ATTENDANCE_ID = '@@class/SET_CLASS_ATTENDANCE_ID',
    CLASS_ATTENDANCE_SUCCESS = '@@class/CLASS_ATTENDANCE_SUCCESS',
    CLASS_ATTENDANCE_ERROR = '@@class/CLASS_ATTENDANCE_ERROR',

    FREE_CLASS_MODE = '@@class/FREE_CLASS_MODE',
    SET_FREE_CLASS_REQUEST = '@@class/SET_FREE_CLASS_REQUEST',
}

export interface EducatorClassState {
    loadingLayouts: boolean;
    readonly classDetail: Object;
    readonly classDetails: Object;
    readonly classMode: string;
    readonly errors?: string;

    readonly testMode: string;
    readonly testDetail: Object;
    readonly testDetails: Object;
    readonly classHistoryDetails: Object;
    readonly classAttendanceId: string;
    readonly classAttendanceDetails: Object;

    readonly freeClassDetail: object;
    readonly freeClassDetails: object;
    readonly freeClassMode: string;
}
