
export enum SpaceshipActionTypes {
    LAYOUT_SPACESHIP_REQUEST = '@@layouts/LAYOUT_SPACESHIP_REQUEST',
    LAYOUT_SPACESHIP_SUCCESS = '@@layouts/LAYOUT_SPACESHIP_SUCCESS',
    LAYOUT_SPACESHIP_ERROR = '@@layouts/LAYOUT_SPACESHIP_ERROR ',
    LAYOUT_EMBLEM_SUCCESS = '@@layouts/LAYOUT_EMBLEM_SUCCESS',
    LAYOUT_AVATAR_SUCCESS = '@@layouts/LAYOUT_AVATAR_SUCCESS',
  }
  
  export interface SpaceshipState {
    readonly spaceships: ISpaceshipPart[];
    readonly loadingSpaceship: boolean;
    readonly emblem: object | null;
    readonly avatar: object | null;
    readonly errors?: string;
  }
  