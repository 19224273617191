import React, { useEffect } from "react"
import { hydrate, render } from "react-dom"
import "./App.scss"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { createBrowserHistory } from "history"
import ConfigureStore from "./configureStore"
import { ConnectedRouter } from "connected-react-router"
import Routes from "./router/routes"
import { ToastContainer } from "react-toastify"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "react-toastify/dist/ReactToastify.css"
import { Store } from "redux"
import { ApplicationState } from "./store"
import { CheckAuthentication } from "./helpers/auth"

const history = createBrowserHistory()
const prevHistoryPush = history.push
let lastLocation = history.location

history.listen((location) => {
  // @ts-ignore
  lastLocation = location
})
history.push = (location: any) => {
  if (
    lastLocation === null ||
    location.pathname !==
      lastLocation.pathname + lastLocation.search + lastLocation.hash ||
    JSON.stringify(location.state) !== JSON.stringify(lastLocation.state)
  ) {
    prevHistoryPush(location)
  }
}

const initialState: any = {}
export const store: Store<ApplicationState> = ConfigureStore(
  history,
  initialState
)

const AppObj = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      {CheckAuthentication()}
      <Routes />
      <ToastContainer />
    </ConnectedRouter>
  </Provider>
)

const rootElement: any = document.getElementById("root")
if (rootElement?.hasChildNodes()) {
  hydrate(AppObj, rootElement)
} else {
  render(AppObj, rootElement)
}

reportWebVitals()
