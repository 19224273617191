import { Reducer } from 'redux';

import { DashboardPlayVideoActionTypes } from './types';

export const initialState: string = '';

const reducer: Reducer<string> = (state = initialState, action) => {
    switch (action.type) {
        case DashboardPlayVideoActionTypes.PLAY_VIDEO_REQUEST: {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

export { reducer as DashboardVideoReducer };
