import React, { useEffect } from "react"
import { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api"
import $ from "jquery"
import useWindowSize from "../../dashboard/useWindowSize"
import "./Popup.scss"
const Popup = ({
  PopupShown,
  ActionToParent,
  eventHandler,
  Message,
  submitButtonTxt = "",
  isCloseIcon = false,
}) => {
  const { width, height }: any = useWindowSize()
  useEffect(() => {
    if (PopupShown) {
      $("body").css("overflow", "hidden")
      $("html").css("overflow", "hidden")
      $("#root").css("overflow", "hidden")
      $("#root").css("height", "100vh")
    } else {
      $("body").css("overflow", "auto")
      $("html").css("overflow", "auto")
      $("#root").css("overflow", "auto")
      $("#root").css("height", "")
    }
    return () => {}
  }, [PopupShown])
  return (
    <>
      {PopupShown ? (
        <>
          <div className="popup">
            <div className="popup_inner">
              {isCloseIcon && (
                <div style={{ padding: "18px" }}>
                  <img
                    src={GITHUB_ASSETS_HOST_URL + `img/popup-close.png`}
                    alt="Close"
                    onClick={() => ActionToParent(!PopupShown)}
                    className="Popup_Close_Btn"
                  />
                </div>
              )}
              <h1 className="popup_Title">{Message}</h1>

              <div className="Popup_Bottom_Container">
                <div
                  className="Popup_Bottom_Cancel popup_btn"
                  onClick={() => ActionToParent(!PopupShown)}
                  style={{ marginRight: "10px" }}
                >
                  Cancel
                </div>
                <div
                  className="Popup_Bottom_Send popup_btn"
                  onClick={() => {
                    eventHandler()
                  }}
                >
                  {submitButtonTxt != "" ? `Submit` : `Yes`}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}

export default React.memo(Popup)
