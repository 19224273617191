import React from "react"
import { lazy } from "react"
import GuestRoute from "../GuestRoute"

const DISCLAIMERPOLICY = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/DISCLAIMER POLICY")
)
const MOBILEDISCLAIMERPOLICY = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/DISCLAIMER_POLICY_MOBILE")
)
const policyteacheruser = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/policyteacheruser")
)
const MeekoTeacher = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoTeacher")
)
const MeekoUsers = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoUsers")
)

const PoliciesRoutes = () => {
  return (
    <>
      <GuestRoute
        exact
        path="/policies/privacy-policy"
        component={DISCLAIMERPOLICY}
      />
      <GuestRoute
        exact
        path="/policies/mobile-privacy-policy"
        component={MOBILEDISCLAIMERPOLICY}
      />
      <GuestRoute
        exact
        path="/policies/privacy-policy-teacher-user"
        component={policyteacheruser}
      />
      <GuestRoute
        exact
        path="/policies/terms-and-conditions-meeko-teacher"
        component={MeekoTeacher}
      />
      <GuestRoute
        exact
        path="/policies/terms-and-conditions-meeko-users"
        component={MeekoUsers}
      />
    </>
  )
}

export default React.memo(PoliciesRoutes)
