import { ActionCreator, Action, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ChatActionTypes } from './types';
import { ApplicationState } from '../index';

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const setElearnerReceiverList: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<Action> => {
        dispatch({
            type: ChatActionTypes.SET_ELEARNERRECEIVERLIST_REQUEST,
        });
        try {
            let payload: any = {}
            return dispatch({
                type: ChatActionTypes.SET_ELEARNERRECEIVERLIST,
                payload: data,
            });
        } catch (e: any) {
            return dispatch({
              type: ChatActionTypes.SET_ELEARNERRECEIVERLIST_ERROR,
              payload: {},
            });
        }
    };
}

export const setElearnerSendMessage: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<Action> => {
        dispatch({
            type: ChatActionTypes.SET_ELEARNERSENDMESSAGE_REQUEST,
        });
        try {
            let payload: any = {}
            return dispatch({
                type: ChatActionTypes.SET_ELEARNERSENDMESSAGE,
                payload: payload?.chat,
            });
        } catch (e: any) {
            return dispatch({
              type: ChatActionTypes.SET_ELEARNERSENDMESSAGE_ERROR,
              payload: {},
            });
        }
    };
}

export const setChatDetailEmpty: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<Action> => {
        return dispatch({
            type: ChatActionTypes.SET_CHATDETAILEMPTY,
            payload: [],
        });
    };
}
