import { Reducer } from 'redux';

import { NotificationActionTypes, NotificationState } from './types';

export const initialState: NotificationState = {
    errors: undefined,
    loading: false,
    notifications: [],
    elearnerNotificationCounter: 0,
    elearnerNotification: [],
    parentNotification: [],
    parentNotificationCounter: 0,
    educatorNotification: [],
    educatorNotificationCounter: 0
}

const reducer: Reducer<NotificationState> = (state = initialState, action) => {
    switch (action.type) {
        case NotificationActionTypes.GET_ALL_NOTIFICATION_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: undefined,
            };
        }
        case NotificationActionTypes.GET_ALL_NOTIFICATION_SUSSESS: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                notifications: action.payload
            };
        }
        case NotificationActionTypes.GET_ALL_NOTIFICATION_ERROR: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                notifications: []
            };
        }
        case NotificationActionTypes.SET_ELEARNERNOTIFICATION_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: undefined,
                elearnerNotification: [],
            };
        }
        case NotificationActionTypes.SET_ELEARNERNOTIFICATION: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                elearnerNotification: action.payload,
            };
        }
        case NotificationActionTypes.SET_ELEARNERNOTIFICATION_ERROR: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                elearnerNotification: [],
            };
        }
        case NotificationActionTypes.SET_ELEARNERNOTIFICATION_COUNTER: {
            return {
                ...state,
                elearnerNotificationCounter: action.payload
            }
        }
        case NotificationActionTypes.EDIT_ELEARNERNOTIFICATION_COUNTER: {
            return {
                ...state,
                elearnerNotificationCounter: (state.elearnerNotificationCounter + 1)
            }
        }
        case NotificationActionTypes.SET_PARENTNOTIFICATION_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: undefined,
                parentNotification: [],
            };
        }
        case NotificationActionTypes.SET_PARENTNOTIFICATION: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                parentNotification: action.payload,
            };
        }
        case NotificationActionTypes.SET_PARENTNOTIFICATION_ERROR: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                parentNotification: [],
            };
        }
        case NotificationActionTypes.SET_PARENTNOTIFICATION_COUNTER: {
            return {
                ...state,
                parentNotificationCounter: action.payload
            }
        }
        case NotificationActionTypes.EDIT_PARENTNOTIFICATION_COUNTER: {
            return {
                ...state,
                parentNotificationCounter: (state.parentNotificationCounter + 1)
            }
        }

        case NotificationActionTypes.SET_EDUCATORNOTIFICATION_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: undefined,
                educatorNotification: [],
            };
        }
        case NotificationActionTypes.SET_EDUCATORNOTIFICATION: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                educatorNotification: action.payload,
            };
        }
        case NotificationActionTypes.SET_EDUCATORNOTIFICATION_ERROR: {
            return {
                ...state,
                loading: false,
                errors: undefined,
                educatorNotification: [],
            };
        }
        case NotificationActionTypes.SET_EDUCATORNOTIFICATION_COUNTER: {
            return {
                ...state,
                educatorNotificationCounter: action.payload
            }
        }
        case NotificationActionTypes.EDIT_EDUCATORNOTIFICATION_COUNTER: {
            return {
                ...state,
                educatorNotificationCounter: (state.educatorNotificationCounter + 1)
            }
        }
        default: {
            return state;
        }
    }
}

export { reducer as NotificationReducer }; 