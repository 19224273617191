import { faSleigh } from '@fortawesome/free-solid-svg-icons';
import { Reducer } from 'redux';

import { DashboardActionTypes, DashboardState, } from './types';

export const initialState: DashboardState = {
  errors: undefined,
  layouts: {
    _id: "",
    email: "",
    mobile: "",
    avatarBody: "",
    avatar: [],
    spaceshipParts: [],
    newSpaceshipPart: {},
    classesBooking: {
      name: "",
      date: "",
      _id: "",
      image: "",
      sessionName: "",
      batchName: "",
    },
    fullName: "",
    birthday: "",
    finalAchievementFlag: false,
    spaceshipPartsFlag: false,
    badgelevel: {
      _id: "",
      image: "",
      video: "",
      badgeName: ""
    },
    recommendedClasses: [{
      name: "",
      date: "",
      _id: ""
    }],
    totalSpaceshipParts: 0,
    avatarLiteCode: '',
    spaceshipLightCode: '',
    badgeLightCode: '',
  },
  loadingLayouts: true
};

const reducer: Reducer<DashboardState> = (state = initialState, action) => {
  switch (action.type) {
    case DashboardActionTypes.LAYOUT_REQUEST: {
      return { ...state, loadingLayouts: true, errors: undefined };
    }
    case DashboardActionTypes.LAYOUT_SUCCESS: {
      return {
        ...state,
        loadingLayouts: false,
        layouts: action.payload.data,
      };
    }
    case DashboardActionTypes.LAYOUT_ERROR: {
      return { ...state, loadingLayouts: false };
    }

    default: {
      return state;
    }
  }
};

export { reducer as DashboardReducer };
