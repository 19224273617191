import React from 'react';
import moment from 'moment'
import api, {
  ENDPOINT,
  decryptAPIRes,
  SMS_URL,
  SMS_USERNAME,
  SMS_PASSWORD,
  SMS_FROM,
  ISLIVE
} from './api'
import axios, { AxiosRequestConfig } from "axios";
export const serializeObject = (obj: any) => {
  return Object.entries(obj).map(([key, val]: any) => `${key}=${encodeURIComponent(val)}`).join('&');
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const setCookie = (name) => {
  var timeToAdd = 1000 * 60 * 60 * 24;
  var date: any = new Date();
  var expiryTime = parseInt(date.getTime()) + timeToAdd;
  date.setTime(expiryTime);
  var utcTime = date;
  document.cookie = name + "=yes; expires=" + utcTime + ";";
}

export const timeSince = (date) => {
  var seconds: any = (moment().local().diff(moment(date).local()) / 1000)
  // moment(date).local().startOf('seconds').fromNow()

  var interval = parseInt(seconds) / 31536000;
  interval = parseInt(seconds) / 3600;
  if (interval > 1) {
    if (interval > 2) {
      return moment(date).local().format("ddd, DD/MM hh:mm")
    } else {
      return Math.floor(interval) + " hours ago";
    }
  }
  interval = parseInt(seconds) / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  interval = parseInt(seconds) / 60;
  if (interval > 1) {
    return Math.floor(interval) + " seconds ago";
  }
  return moment(date).local().format("ddd, DD/MM hh:mm")
}

var REFERENCE = moment();
var TODAY = REFERENCE.clone().startOf('day');
var YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
var A_WEEK_OLD = REFERENCE.clone().subtract(7, 'days').startOf('day');

const isToday = (momentDate) => {
  return momentDate.isSame(TODAY, 'd');
}
const isYesterday = (momentDate) => {
  return momentDate.isSame(YESTERDAY, 'd');
}
const isWithinAWeek = (momentDate) => {
  return momentDate.isAfter(A_WEEK_OLD);
}

export const chatTimeWithGroup = (date) => {
  if (isToday(moment(date).local())) {
    return 'Today'
  } else if (isYesterday(moment(date).local())) {
    return 'Yesterday'
  } else if (isWithinAWeek(moment(date).local())) {
    return moment(date).local().format('dddd')
  } else {
    return moment(date).local().format('ddd, DD MMM')
  }
}

export const chatTimeWithGroupEdu = (date) => {
  if (isToday(moment(date).local())) {
    return moment(date).local().format('hh:mm a')
  } else if (isYesterday(moment(date).local())) {
    return moment(date).local().format('ddd')
  } else if (isWithinAWeek(moment(date).local())) {
    return moment(date).local().format('ddd')
  } else {
    return moment(date).local().format('MMM DD')
  }
}

export const orderId = (number) => {
  var text = "";
  // var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var possible = "0123456789";

  for (var i = 0; i < number; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return "MEEKO" + text;
}

export const getBatch = (date) => {
  const newDate: any = new Date(date);
  const hours: any = newDate.getHours();
  if (hours >= 0 && hours <= 11) {
    return "Morning";
  } else if (hours >= 12 && hours <= 16) {
    return "Afternoon";
  } else if (hours > 16 && hours <= 23) {
    return "Evening";
  }
};

export const sendSMS = (smsTo, smsTxt, templateId) => {
  try {
    if (ISLIVE == "true") {
      let smsUrlEndPoint = `${SMS_URL}username=${SMS_USERNAME}&password=${SMS_PASSWORD}&unicode=false&from=${SMS_FROM}&to=${smsTo}&text=${smsTxt}&dltContentId=${templateId}`


      fetch(smsUrlEndPoint, {
        method: 'GET',
      }).then(response => response.json())
        .then(json => console.log(json))
        .catch(error => console.log('Authorization failed: ' + error.message));
    }
  } catch (e: any) {
    console.log(e.message)
  }
}

export const ArrayIsSorted = (arr) => {
  let second_index;
  for (let first_index = 0; first_index < arr.length; first_index++) {
    second_index = first_index + 1;
    if (arr[second_index] != undefined) {
      if (new Date(arr[second_index].date).getTime() - new Date(arr[first_index].date).getTime() < 0) return false;
    }
  }
  return true;
}