
export enum DashboardActionTypes {
  LAYOUT_REQUEST = '@@layouts/PRODUCTS_REQUEST',
  LAYOUT_SUCCESS = '@@layouts/LAYOUT_SUCCESS',
  LAYOUT_ERROR = '@@layouts/LAYOUT_ERROR',
}

export interface DashboardState {
  readonly layouts: ILayout;
  readonly loadingLayouts: boolean;
  readonly errors?: string;
}
