import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { connect } from "react-redux"
// import { CheckAuthentication } from '../helpers/auth';

interface MyRouteProps extends RouteProps {
  component: any
  authenticated: boolean
  role: string
  rest?: any
}

const GuestRoute: React.FC<MyRouteProps> = ({
  component: Component,
  authenticated,
  role,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated && role === "ELearner" ? (
          <Redirect to="/dashboard/" />
        ) : authenticated && role === "Educator" ? (
          <Redirect to="/educator/dashboard/" />
        ) : authenticated && role === "Parent" ? (
          <Redirect to="/parent/dashboard/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
  role: state.auth.role,
})

export default connect(mapStateToProps)(GuestRoute)
