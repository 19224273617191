import { ActionCreator, Action, Dispatch } from "redux"
import { ThunkAction } from "redux-thunk"

import api, { decryptAPIRes, ENDPOINT, socket } from "../../helpers/api"
// import { serializeObject } from '../../helpers/helpFunctions';
import { AuthActionTypes } from "./types"
import { ApplicationState } from "../index"
import { toast } from "react-toastify"
import axios, { AxiosRequestConfig } from "axios"
import { socketUpdate } from "../../helpers/socket"
// import {AuthpayloadData} from '../../AuthPayload'

export type AppThunk = ActionCreator<
  ThunkAction<void, ApplicationState, null, Action<string>>
>

// const params = {
//   client_id: 'customer',
//   client_secret: 'customerPassword',
//   scope: 'customerApi',
//   grant_type: 'password',
// };

export const accessAuthRequest: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      console.log(data, "=====request")
      let payload: any = null
      const token = data.token
      const screen = data?.screen ? data?.screen : "mobile-dash"
      const ccavEncode = data?.ccavEncode ? data?.ccavEncode : ""
      const config: AxiosRequestConfig = {
        method: "get",
        url: ENDPOINT + `/auth`,
        headers: {
          "x-authorization": `${token}`,
        },
      }

      payload = await axios(config)
      payload = decryptAPIRes(payload.data.data)
      localStorage.setItem("token", token)
      localStorage.setItem("role", payload.data.role)
      localStorage.setItem("mobiledashenable", "true")
      api.defaults.headers.common["x-authorization"] = token
      console.log(payload, "========response")
      if (payload.data.role === "ELearner") {
        localStorage.setItem("userId", payload.data.details._id)
        localStorage.setItem("profile", payload.data.details.profilePic.path)
        localStorage.setItem("roomNo", payload.data.details.roomNo)
        localStorage.setItem("userDetail", JSON.stringify(payload.data.details))
        localStorage.setItem("parentId", payload.data.details.parentId)
      }
      if (payload.data.role === "Educator") {
        localStorage.setItem("userId", payload.data.details._id)
        localStorage.setItem("profile", payload.data.details.profilePic.path)
        localStorage.setItem("roomNo", payload.data.details.roomNo)
        localStorage.setItem("userDetail", JSON.stringify(payload.data.details))
      }
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: { role: payload.data.role },
      })
      setTimeout(() => {
        if (screen === "avatar") {
          history.push("/profile/mobile-avatar")
        } else if (screen === "spaceDock") {
          history.push("/profile/mobile-spaceship", { mobileDash: true })
        } else if (screen === "meekoJourney") {
          history.push("/profile/mobile-meekoJourney", { mobileDash: true })
        } else if (screen === "liveClass") {
          console.log(data, "============data")
          localStorage.setItem("classId", data?.classId)
          localStorage.setItem("sessionId", data?.sessionId)
          localStorage.setItem("type", data?.type)
          localStorage.setItem("filePath", data?.filePath)
          if (payload.data.role === "Educator") {
            history.push("/educator/mobile-white-board")
          } else {
            history.push("/mobile-white-board", { mobileDash: true })
          }
        } else if (screen == "classBooking") {
          localStorage.setItem("ccavEncode", data?.ccavEncode)
          if (payload.data.role == "Parent") {
            history.push("/parent/mobile-classbooking-payment")
          } else if (payload.data.role == "Educator") {
          } else {
            history.push("/learner/mobile-classbooking-payment")
          }
        } else if (screen == "payment") {
          localStorage.setItem("ccavEncode", data?.ccavEncode)
          if (payload.data.role == "Parent") {
            history.push("/parent/mobile-payment")
          } else if (payload.data.role == "Educator") {
            history.push("/educator/mobile-payment")
          } else {
            history.push("/mobile-payment")
          }
        } else if (screen === "MyClass") {
          if (payload.data.role === "Educator") {
            history.push("/educator/mobile-classes")
          } else {
            history.push("/")
          }
        } else {
          history.push("/mobile-dash", { mobileDash: true })
        }
      }, 7)
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } catch (e: any) {
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

export const authRequest: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      let payload: any = null
      if (data && data.role === "parent") {
        payload = await api.post("/parent/signin", data)
      } else if (data && data.role === "child") {
        payload = await api.post("/child/signin", data)
      } else if (data && data.role === "educator") {
        payload = await api.post("/educator/signin", data)
      }

      let result = payload.data
      if (result.flag) {
        // const socket: any = socketIOClient(SOKET_END_POINT || "", { transports: ['websocket'] });
        socket.on("connect", async () => {
          console.log("socket connected ---------------->")
          socketUpdate()
        })
        let token = result.token
        localStorage.setItem(
          "userDetail",
          JSON.stringify(payload?.data?.details)
        )
        if (data.role == "educator") {
          localStorage.setItem("userId", payload.data.details._id)
        }
        if (payload?.data?.role === "ELearner") {
          localStorage.setItem("parentId", payload?.data?.details?.parentId)
        }
        localStorage.setItem("userId", payload?.data?.details?._id)
        localStorage.setItem("token", token)
        localStorage.setItem("roomNo", payload?.data?.details?.roomNo)
        localStorage.setItem("role", payload.data.role)
        if (payload.data.details.bankDetailFlag) {
          localStorage.setItem("websiteTour", payload.data.details.websiteTour)
        } else {
          localStorage.setItem("websiteTour", "false")
        }
        socketUpdate()
        if (payload?.data?.details?.profilePic?.path) {
          localStorage.setItem(
            "profile",
            payload?.data?.details?.profilePic?.path
          )
        }
        api.defaults.headers.common["x-authorization"] = token
        dispatch({
          type: AuthActionTypes.SET_AUTHENTICATED,
          payload: payload.data,
        })
        dispatch({
          type: AuthActionTypes.SET_USER_PROFILE,
          payload: payload.data,
        })
        // toast.success(`Welcome to Meeko World`)
        if (data) {
          if (data.role === "child") {
            const token: any = localStorage.getItem("token")
            const role: any = localStorage.getItem("role")
            if (token && role) {
              try {
                const config: AxiosRequestConfig = {
                  method: "get",
                  url: ENDPOINT + "/auth/check",
                  headers: {
                    "x-authorization": `${token}`,
                  },
                }
                axios(config)
                  .then(function (response) {
                    var payload = decryptAPIRes(response.data.data)
                    if (payload.code === 200) {
                    } else {
                      if (role == "ELearner") {
                        history.push("/expired-package")
                      }
                    }
                  })
                  .catch(function (error) {
                    var data = decryptAPIRes(error)
                    console.log("error", data)
                  })
              } catch (err) {
                console.log("err", err)
              }
            }
            // history.push('/dashboard');
          } else if (data.role === "parent") {
            console.log(payload?.data, "=============data 1")
            if (payload?.data?.details?.isChild) {
              history.push("/parent/dashboard")
            } else {
              history.push("/parent/no-child")
            }
          } else if (data.role === "educator") {
            if (payload?.data?.details?.bankDetailFlag) {
              history.push("/educator/dashboard", { bankDetails: "false" })
            } else {
              history.push("/educator/dashboard", { bankDetails: "true" })
            }
          }
        }
        return dispatch({
          type: AuthActionTypes.AUTH_SUCCESS,
        })
      } else {
        toast.error(`${result?.message}`)
        return dispatch({
          type: AuthActionTypes.AUTH_ERROR,
          payload: result?.message,
        })
      }
    } catch (e: any) {
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

export const childLogin: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    if (data.flag) {
      // const socket: any = socketIOClient(SOKET_END_POINT || "", { transports: ['websocket'] });
      socket.on("connect", async () => {
        console.log("socket connected ---------------->")
        socketUpdate()
      })
      let token = data.token
      localStorage.setItem("userDetail", JSON.stringify(data?.details))
      if (data.role == "educator") {
        localStorage.setItem("userId", data.details._id)
      }
      if (data?.role === "ELearner") {
        localStorage.setItem("parentId", data?.details?.parentId)
      }
      localStorage.setItem("userId", data?.details?._id)
      localStorage.setItem("token", token)
      localStorage.setItem("roomNo", data?.details?.roomNo)
      localStorage.setItem("role", data.role)
      if (data.details.bankDetailFlag) {
        localStorage.setItem("websiteTour", data.details.websiteTour)
      } else {
        localStorage.setItem("websiteTour", "false")
      }
      socketUpdate()
      if (data?.details?.profilePic?.path) {
        localStorage.setItem("profile", data?.details?.profilePic?.path)
      }
      api.defaults.headers.common["x-authorization"] = token
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: data,
      })
      dispatch({
        type: AuthActionTypes.SET_USER_PROFILE,
        payload: data,
      })
      // toast.success(`Welcome to Meeko World`)
      if (data) {
        if (data.role === "child") {
          const token: any = localStorage.getItem("token")
          const role: any = localStorage.getItem("role")
          if (token && role) {
            try {
              const config: AxiosRequestConfig = {
                method: "get",
                url: ENDPOINT + "/auth/check",
                headers: {
                  "x-authorization": `${token}`,
                },
              }
              axios(config)
                .then(function (response) {
                  var payload = decryptAPIRes(response.data.data)
                  if (payload.code === 200) {
                  } else {
                    if (role == "ELearner") {
                      history.push("/expired-package")
                    }
                  }
                })
                .catch(function (error) {
                  var data = decryptAPIRes(error)
                  console.log("error", data)
                })
            } catch (err) {
              console.log("err", err)
            }
          }
          // history.push('/dashboard');
        } else if (data.role === "parent") {
          console.log(data, "=============data 1")
          if (data?.details?.isChild) {
            history.push("/parent/dashboard")
          } else {
            history.push("/parent/no-child")
          }
        } else if (data.role === "educator") {
          if (data?.details?.bankDetailFlag) {
            history.push("/educator/dashboard", { bankDetails: "false" })
          } else {
            history.push("/educator/dashboard", { bankDetails: "true" })
          }
        }
      }
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } else {
      toast.error(`${data?.message}`)
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: data?.message,
      })
    }
  }
}

export const adminAccessAuthRequest: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      let payload: any = null
      const token = data.token

      const config: AxiosRequestConfig = {
        method: "get",
        url: ENDPOINT + `/auth`,
        headers: {
          "x-authorization": `${token}`,
        },
      }

      payload = await axios(config)
      payload = decryptAPIRes(payload.data.data)
      localStorage.setItem("token", token)
      localStorage.setItem("role", payload.data.role)
      localStorage.setItem("mobiledashenable", "true")
      api.defaults.headers.common["x-authorization"] = token

      socket.on("connect", async () => {
        console.log("socket connected ---------------->")
        socketUpdate()
      })
      localStorage.setItem("userDetail", JSON.stringify(payload?.data?.details))
      if (data.role == "educator") {
        localStorage.setItem("userId", payload.data.details._id)
      }
      localStorage.setItem("userId", payload?.data?.details?._id)
      localStorage.setItem("token", token)
      localStorage.setItem("roomNo", payload?.data?.details?.roomNo)
      localStorage.setItem("role", payload.data.role)
      if (payload.data.details.bankDetailFlag) {
        localStorage.setItem("websiteTour", payload.data.details.websiteTour)
      } else {
        localStorage.setItem("websiteTour", "false")
      }
      socketUpdate()
      if (payload?.data?.details?.profilePic?.path) {
        localStorage.setItem(
          "profile",
          payload?.data?.details?.profilePic?.path
        )
      }
      api.defaults.headers.common["x-authorization"] = token
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: payload.data,
      })
      dispatch({
        type: AuthActionTypes.SET_USER_PROFILE,
        payload: payload.data,
      })
      // toast.success(`Welcome to Meeko World`)

      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: { role: payload.data.role },
      })
      setTimeout(() => {
        if (payload.data.role === "ELearner") {
          history.push("/dashboard")
        } else if (payload.data.role === "Parent") {
          history.push("/parent/dashboard")
        } else if (payload.data.role === "Educator") {
          history.push("/educator/dashboard")
        } else {
          history.push("/")
        }
      }, 7)
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } catch (e: any) {
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

export const parentAccessAuthRequest: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      let payload: any = null
      const token = data.token

      const config: AxiosRequestConfig = {
        method: "get",
        url: ENDPOINT + `/auth`,
        headers: {
          "x-authorization": `${token}`,
        },
      }

      payload = await axios(config)
      payload = decryptAPIRes(payload.data.data)
      console.log(payload, "=======payload")
      localStorage.setItem("userDetail", JSON.stringify(payload?.data?.details))

      localStorage.setItem("userId", payload?.data?.details?._id)
      localStorage.setItem("token", token)
      localStorage.setItem("roomNo", payload?.data?.details?.roomNo)
      localStorage.setItem("role", payload.data.role)
      if (payload.data.details.bankDetailFlag) {
        localStorage.setItem("websiteTour", payload.data.details.websiteTour)
      } else {
        localStorage.setItem("websiteTour", "false")
      }
      socketUpdate()
      if (payload?.data?.details?.profilePic?.path) {
        localStorage.setItem(
          "profile",
          payload?.data?.details?.profilePic?.path
        )
      }
      api.defaults.headers.common["x-authorization"] = token

      localStorage.setItem("token", token)
      localStorage.setItem("role", payload.data.role)
      api.defaults.headers.common["x-authorization"] = token

      socket.on("connect", async () => {
        console.log("socket connected ---------------->")
        socketUpdate()
      })
      localStorage.setItem("userDetail", JSON.stringify(payload?.data?.details))

      localStorage.setItem("userId", payload?.data?.details?._id)
      localStorage.setItem("token", token)

      localStorage.setItem("role", payload.data.role)
      if (payload.data.details.bankDetailFlag) {
        localStorage.setItem("websiteTour", payload.data.details.websiteTour)
      } else {
        localStorage.setItem("websiteTour", "false")
      }
      socketUpdate()
      if (payload?.data?.details?.profilePic?.path) {
        localStorage.setItem(
          "profile",
          payload?.data?.details?.profilePic?.path
        )
      }
      api.defaults.headers.common["x-authorization"] = token
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: payload.data,
      })
      dispatch({
        type: AuthActionTypes.SET_USER_PROFILE,
        payload: payload.data,
      })
      // toast.success(`Welcome to Meeko World`)

      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: { role: payload.data.role },
      })
      setTimeout(() => {
        if (payload.data.role === "ELearner") {
          history.push("/dashboard")
        } else if (payload.data.role === "Parent") {
          if (payload.data.details.isChild) {
            history.push("/parent/dashboard")
          } else {
            history.push("/parent/no-child")
          }
        } else if (payload.data.role === "Educator") {
          history.push("/educator/dashboard")
        } else {
          history.push("/")
        }
      }, 7)
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } catch (e: any) {
      console.log(e.response.status, "============here")
      if (e && e.response && e.response.status && e.response.status === 401) {
        history.push("/parent/login")
      }
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

// export const educatorAuthRequest: AppThunk = (data, history) => {
//   return async (dispatch: Dispatch): Promise<Action> => {
//     dispatch({
//       type: AuthActionTypes.AUTH_REQUEST,
//     });
//     try {
//       let token = data.token;
//       localStorage.setItem('token', token);
//       localStorage.setItem('role', data.role);
//       api.defaults.headers.common['x-authorization'] = token;
//       dispatch({ type: AuthActionTypes.SET_AUTHENTICATED, payload: { role: data.role } });
//       toast.success(`Welcome to Meeko World`)
//       history.push('/educator/signup/steps');
//       return dispatch({
//         type: AuthActionTypes.AUTH_SUCCESS,
//       });
//     } catch (e: any) {
//       return dispatch({
//         type: AuthActionTypes.AUTH_ERROR,
//         payload: e.response?.data?.message,
//       });
//     }
//   };
// };

export const eduAuthRequest: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      let token = data.token
      localStorage.setItem("token", token)
      localStorage.setItem("role", data.role)
      api.defaults.headers.common["x-authorization"] = token
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: { role: data.role },
      })
      // toast.success(`Welcome to Meeko World`)
      socketUpdate()
      // history.push('/educator/signup/steps');
      history.push("/educator/dashboard")
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } catch (e: any) {
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

export const parentAuthRequest: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      let token = data.token
      localStorage.setItem("token", token)
      localStorage.setItem("role", data.role)
      api.defaults.headers.common["x-authorization"] = token
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: { role: data.role },
      })
      // toast.success(`Welcome to Meeko World`)
      socketUpdate()
      if (data.isChild === undefined) {
        history.push("/parent/no-child/")
      } else {
        if (data.isChild) {
          history.push("/parent/dashboard")
        }
      }
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } catch (e: any) {
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

export const parentAuthRequestFromThankyou: AppThunk = (data, history) => {
  return async (dispatch: Dispatch): Promise<Action> => {
    dispatch({
      type: AuthActionTypes.AUTH_REQUEST,
    })
    try {
      let token = data.token
      localStorage.setItem("token", token)
      localStorage.setItem("role", data.role)
      api.defaults.headers.common["x-authorization"] = token
      dispatch({
        type: AuthActionTypes.SET_AUTHENTICATED,
        payload: { role: data.role },
      })
      // toast.success(`Welcome to Meeko World`)
      socketUpdate()
      // history.push('/parent/Nochild');
      return dispatch({
        type: AuthActionTypes.AUTH_SUCCESS,
      })
    } catch (e: any) {
      return dispatch({
        type: AuthActionTypes.AUTH_ERROR,
        payload: e.response?.data?.message,
      })
    }
  }
}

export const logoutUser: AppThunk = () => {
  return (dispatch: Dispatch): Action => {
    if (localStorage.token) {
      console.log("calldisconnect event called")
      socket.emit("calldisconnect")
      localStorage.removeItem("userId")
      localStorage.removeItem("token")
      localStorage.removeItem("role")
      localStorage.removeItem("profile")
      localStorage.removeItem("selectedChild")
      localStorage.removeItem("profile")
      localStorage.removeItem("socketid")
      localStorage.removeItem("roomNo")
      localStorage.removeItem("websiteTour")
      localStorage.removeItem("selectedChildToken")
      localStorage.removeItem("userDetail")
      localStorage.clear()
    }
    window.location.href = "/"
    return dispatch({
      type: AuthActionTypes.SET_UNAUTHENTICATED,
    })
  }
}
