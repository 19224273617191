import api, { socket } from "./api"
import { store } from "../index"
import { ChatActionTypes } from "../store/chat/types"
import { NotificationActionTypes } from "../store/notification/types"
import axios from "axios"

export const socketUpdate = async () => {
  // alert('socketUpdate called')
  // socket.emit('calldisconnect')
  socket.on("sendnotification", async (data) => {})

  if (localStorage.getItem("token") && localStorage.getItem("roomNo")) {
    console.log("socketupdate event called")
    socket.emit("socketupdate", {
      token: localStorage.getItem("token"),
      socketid: localStorage.getItem("socketid") || "",
      roomNo: localStorage.getItem("roomNo") || "",
    })
  }

  socket.on("socketid", async (data) => {
    localStorage.setItem("socketid", data)
  })
  socket.on("educatorreceiverlist", async (data) => {
    // console.log(data, 'educatorreceiverlist')
    store.dispatch({
      type: ChatActionTypes.SET_EDUCATORRECEIVERLIST,
      payload: data,
    })
  })

  socket.on("elearnerreceiverlist", async (data) => {
    // console.log(data, 'elearnerreceiverlist')
    store.dispatch({
      type: ChatActionTypes.SET_ELEARNERRECEIVERLIST,
      payload: data,
    })
  })

  socket.on("ping", function (data) {})
  socket.on("pong", function (data) {})
  socket.on("notification", async (data) => {
    try {
      // console.log(data, 'notification')
      if (localStorage.getItem("role")) {
        if (localStorage.getItem("role") == "ELearner") {
          store.dispatch({
            type: NotificationActionTypes.SET_ELEARNERNOTIFICATION_REQUEST,
          })
          store.dispatch({
            type: NotificationActionTypes.SET_ELEARNERNOTIFICATION,
            payload: data,
          })
        } else if (localStorage.getItem("role") == "Parent") {
          store.dispatch({
            type: NotificationActionTypes.SET_PARENTNOTIFICATION_REQUEST,
          })
          store.dispatch({
            type: NotificationActionTypes.SET_PARENTNOTIFICATION,
            payload: data,
          })
        } else {
          store.dispatch({
            type: NotificationActionTypes.SET_EDUCATORNOTIFICATION,
            payload: data,
          })
        }
      }
    } catch (e: any) {
      if (localStorage.getItem("role") == "ELearner") {
        store.dispatch({
          type: NotificationActionTypes.SET_PARENTNOTIFICATION_ERROR,
        })
      } else if (localStorage.getItem("role") == "ELearner") {
        store.dispatch({
          type: NotificationActionTypes.SET_ELEARNERNOTIFICATION_ERROR,
        })
      }
    }
  })
  if (
    localStorage.getItem("token") &&
    localStorage.getItem("role") &&
    localStorage.getItem("roomNo")
  ) {
    socket.emit("unreadnotification", {
      token: localStorage.getItem("token") || "",
      // type: localStorage.getItem('role') || '',
      roomNo: localStorage.getItem("roomNo") || "",
    })
  }

  const currentUrl = window.location.href
  if (currentUrl.includes("live-class")) {
    const room = localStorage.getItem("roomName")
    if (localStorage.getItem("token")) {
      console.log("here in room rejoin")
      // socketJoinroom(room)
      const roleId = localStorage.getItem("role")
      const newCurrentUrl = window.location.href.split("/")
      const sessionId = newCurrentUrl[newCurrentUrl.length - 1]
      const classId = newCurrentUrl[newCurrentUrl.length - 2]
      if (sessionId && classId) {
        setTimeout(async () => {
          if (
            localStorage.getItem("elearnerClassId") === undefined ||
            localStorage.getItem("elearnerSessionId") === undefined ||
            localStorage.getItem("elearnerClassDetailId") === undefined ||
            localStorage.getItem("expiredNotificationSecond") === undefined ||
            localStorage.getItem("liveClassExpire") === undefined ||
            localStorage.getItem("roomName") === undefined
          ) {
            console.log("test liveclass is called..... 5")
            const payload = await api.post(
              `/liveclass/${roleId === "Educator" ? "educator" : "elearner"}`,
              {
                classId: classId,
                sessionId: sessionId,
              }
            )
            if (payload.status === 200) {
              console.log("test socketJoinroom is called..... 3")
              socketJoinroom(room)
              if (roleId === "ELearner") {
                localStorage.setItem(
                  "elearnerClassId",
                  payload.data.classDetail.classId
                )
                localStorage.setItem(
                  "elearnerSessionId",
                  payload.data.classDetail.sessionId
                )
                localStorage.setItem(
                  "elearnerClassDetailId",
                  payload.data.classDetail.classDetailId
                )
              }
              if (roleId === "Educator") {
                localStorage.setItem(
                  "expiredNotificationSecond",
                  payload.data.expiredNotificationSecond
                )
                localStorage.setItem(
                  "liveClassExpire",
                  payload.data.expiredTotalSecond
                )
              }
              localStorage.setItem("roomName", payload.data.roomName)
              if (roleId === "ELearner") {
                if (payload.data.educatorLive) {
                  console.log(
                    payload.data.totalSecond,
                    "====data.totalSecond 3"
                  )
                  socket.emit("elearnerlive", {
                    roomName: payload.data.roomName,
                    classId: payload.data.classDetail.classId,
                    sessionId: payload.data.classDetail.sessionId,
                    previousSecond: payload.data.totalSecond,
                    flag: true,
                  })
                }
              } else {
                socket.emit("educatorlive", {
                  roomName: payload.data.roomName,
                  classId: payload.data.classDetail.classId,
                  sessionId: payload.data.classDetail.sessionId,
                  previousSecond: payload.data.totalSecond,
                })
              }
            } else {
              console.log(window.location.origin, "===window.location.origin")
              if (roleId === "Educator") {
                window.location.replace(
                  window.location.origin + "/educator/dashboard"
                )
                //   history.push("/educator/dashboard")
              } else {
                //   history.push("/dashboard")
                window.location.replace(window.location.origin + "/dashboard/")
              }
            }
          }
        }, 5000)
      }
    }
  }
}

socket.on("connect", async () => {
  console.log("socket connected from socket file ---------------->")
  socketUpdate()
  axios
    .get("https://ipapi.co/json/")
    .then((response) => {
      let data = response.data
      console.log(data?.currency, "====data")
      localStorage.setItem("currency", data?.currency)
    })
    .catch((error) => {
      console.log(error)
    })
})

socket.on("returncalldisconnect", async () => {
  if(localStorage.getItem("token") && localStorage.getItem("role")) {
    console.log("here in returncalldisconnect")
    if (localStorage.getItem("role") == "ELearner") {
      const payload: any = await api.post("/calldisconnect/calldisconnectchild/")
      if(payload?.status == 200) {
        localStorage.setItem("socketid", "")
        // history.push("/dashboard", { fromPopup: "from-liveclass" })
        socketUpdate()
        window.location.replace(
          window.location.origin + "/dashboard"
        )
      }
    } else if (localStorage.getItem("role") == "Parent") {
      const payload: any = await api.post("/calldisconnect/calldisconnectparent/")
    } else if(localStorage.getItem("role") == "Educator") {
      const payload: any = await api.post("/calldisconnect/calldisconnecteducator/")
      if(payload?.status == 200) {
        localStorage.removeItem("liveClassExpire")
        localStorage.removeItem("expiredNotificationSecond")
        localStorage.setItem("socketid", "")
        socketUpdate()
        window.location.replace(
          window.location.origin + "/educator/dashboard"
        )
      }
    }
  }
})


export const getNotification = (notificationLimit) => {
  if (localStorage.getItem("role")) {
    socket.emit("unreadnotification", {
      token: localStorage.getItem("token") || "",
      // type: localStorage.getItem('role') || '',
      roomNo: localStorage.getItem("roomNo") || "",
    })
    socket.emit("notification", {
      limit: notificationLimit,
      token: localStorage.getItem("token"),
      // type: localStorage.getItem('role')
    })
  }
}

export const setReadNotification = (notificationId) => {
  if (localStorage.getItem("role")) {
    socket.emit("readnotification", {
      id: notificationId,
      token: localStorage.getItem("token") || "",
      // type: localStorage.getItem('role') || '',
    })
    getNotification(-1)
  }
}

export const allReadNotification = () => {
  if (localStorage.getItem("role")) {
    socket.emit("allreadnotification", {
      token: localStorage.getItem("token") || "",
      // type: localStorage.getItem('role') || '',
    })
    getNotification(-1)
  }
}

socket.on("notification", async (data) => {
  // console.log(data, 'notification')
  try {
    if (localStorage.getItem("role")) {
      if (localStorage.getItem("role") == "ELearner") {
        store.dispatch({
          type: NotificationActionTypes.SET_ELEARNERNOTIFICATION_REQUEST,
        })
        store.dispatch({
          type: NotificationActionTypes.SET_ELEARNERNOTIFICATION,
          payload: data,
        })
      } else if (localStorage.getItem("role") == "Parent") {
        store.dispatch({
          type: NotificationActionTypes.SET_PARENTNOTIFICATION_REQUEST,
        })
        store.dispatch({
          type: NotificationActionTypes.SET_PARENTNOTIFICATION,
          payload: data,
        })
      } else {
        store.dispatch({
          type: NotificationActionTypes.SET_EDUCATORNOTIFICATION_REQUEST,
        })
        store.dispatch({
          type: NotificationActionTypes.SET_EDUCATORNOTIFICATION,
          payload: data,
        })
      }
    }
  } catch (e: any) {
    if (localStorage.getItem("role") == "ELearner") {
      store.dispatch({
        type: NotificationActionTypes.SET_PARENTNOTIFICATION_ERROR,
      })
    } else if (localStorage.getItem("role") == "ELearner") {
      store.dispatch({
        type: NotificationActionTypes.SET_ELEARNERNOTIFICATION_ERROR,
      })
    } else if (localStorage.getItem("role") == "Educator") {
      store.dispatch({
        type: NotificationActionTypes.SET_EDUCATORNOTIFICATION_ERROR,
      })
    }
  }
})

socket.on("unreadnotification", async (data) => {
  // console.log(data, 'unreadnotification')
  try {
    if (localStorage.getItem("role")) {
      if (localStorage.getItem("role") == "Parent") {
        store.dispatch({
          type: NotificationActionTypes.SET_PARENTNOTIFICATION_COUNTER,
          payload: data,
        })
      } else if (localStorage.getItem("role") == "ELearner") {
        store.dispatch({
          type: NotificationActionTypes.SET_ELEARNERNOTIFICATION_COUNTER,
          payload: data,
        })
      } else if (localStorage.getItem("role") == "Educator") {
        store.dispatch({
          type: NotificationActionTypes.SET_EDUCATORNOTIFICATION_COUNTER,
          payload: data,
        })
      }
    }
  } catch (e: any) {
    console.log(e)
  }
})

socket.on("sendnotification", async (data) => {
  if (localStorage.getItem("role") == "ELearner") {
    store.dispatch({
      type: NotificationActionTypes.EDIT_ELEARNERNOTIFICATION_COUNTER,
    })
  } else if (localStorage.getItem("role") == "Parent") {
    store.dispatch({
      type: NotificationActionTypes.EDIT_PARENTNOTIFICATION_COUNTER,
    })
  } else if (localStorage.getItem("role") == "Educator") {
    store.dispatch({
      type: NotificationActionTypes.EDIT_EDUCATORNOTIFICATION_COUNTER,
    })
  }
})

socket.on("ping", function (data) {})
socket.on("pong", function (data) {})

export const socketJoinroom = (roomName) => {
  console.log("joinroom event called")
  socket.emit("joinroom", roomName)
}

socket.on("socketid", async (data) => {
  localStorage.setItem("socketid", data)
})

socket.on("receivedmessage", async (data) => {
  // console.log(data, 'receivedmessage')
  store.dispatch({
    type: ChatActionTypes.SET_ELEARNERSENDMESSAGE,
    payload: data,
  })
})

socket.on("chatdetails", async (data) => {
  // console.log(data, 'chatdetails')
  store.dispatch({ type: ChatActionTypes.SET_CHATDETAILS, payload: data })
  if (
    localStorage.getItem("role") == "ELearner" ||
    localStorage.getItem("role") == "Parent"
  ) {
    store.dispatch({
      type: ChatActionTypes.SET_CURRENTELEARNERRECEIVERLIST,
      payload: data,
    })
  } else if (localStorage.getItem("role") == "Educator") {
    store.dispatch({
      type: ChatActionTypes.SET_CURRENTEDUCATORRECEIVERLIST,
      payload: data,
    })
  }
})

socket.on("educatorreceiverlist", async (data) => {
  // console.log(data, 'educatorreceiverlist')
  store.dispatch({
    type: ChatActionTypes.SET_EDUCATORRECEIVERLIST,
    payload: data,
  })
})

socket.on("elearnerreceiverlist", async (data) => {
  // console.log(data, 'elearnerreceiverlist')
  // alert('here in elearnerreceiverlist')
  store.dispatch({
    type: ChatActionTypes.SET_ELEARNERRECEIVERLIST,
    payload: data,
  })
})
