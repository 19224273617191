import React from "react"
import { lazy } from "react"

import GuestRoute from "../GuestRoute"
const EducatorPrivateRoute = lazy(() => import("./EducatorPrivateRoute"))
const ClassHistory = lazy(
  () => import("../../components/EducationUi/ClassHistory/ClassHistory")
)
const ClassAttendance = lazy(
  () => import("../../components/EducationUi/ClassAttendance/ClassAttendance")
)
const CreateTest = lazy(
  () => import("../../components/EducationUi/CreateTest/CreateTest")
)
const Edu_Create_Test = lazy(
  () => import("../../components/EducationUi/CreateTest/AddTest")
)
// const EducationProfile = lazy(
//   () => import("../../components/EducationUi/EducationProfile/EducationProfile")
// );
const EducationProfile = lazy(
  () =>
    import("../../components/EducationUi/EducationProfile/NewEducationProfile")
)
const MySpace = lazy(
  () => import("../../components/EducationUi/MySpace/MySpace")
)
const NotificationPage = lazy(
  () => import("../../components/EducationUi/Notification/NotificationPage")
)
const ReferPage = lazy(
  () => import("../../components/EducationUi/ReferPage/ReferPage")
)

const NewReferPage = lazy(
  () => import("../../components/EducationUi/NewReferPage/NewReferPage")
)

const ReferEarnHistory = lazy(
  () => import("../../components/EducationUi/ReferEarnHistory/ReferEarnHistory")
)

const Howitwork = lazy(
  () => import("../../components/EducationUi/Howitwork/Howitwork")
)
const EduSteps = lazy(() => import("../../components/educator/Signup/Steps"))
const Edu_Dashboard = lazy(
  () => import("../../components/educator/Educational_Dashbord/Edu_Dashboard")
)
const Reschedule = lazy(
  () => import("../../components/educator/Reschedule/Reschedule")
)

const LiveClasses = lazy(
  () => import("../../components/liveClasses/liveClasses")
)
const MobileLiveClasses = lazy(
  () => import("../../components/liveClasses/MobileLiveClasses")
)
const Calendar = lazy(
  () => import("../../components/EducationUi/Calender/Calender")
)
const Edu_Create_Class = lazy(
  () => import("../../components/educator/MyClasses/CreateClass")
)
const FreeClass = lazy(
  () => import("../../components/educator/Free_class/FreeClass")
)
const Edu_Create_PreRecordedClass = lazy(
  () => import("../../components/educator/PreRecordedClass/PreRecordedClass")
)
const EduClassList = lazy(
  () => import("../../components/educator/MyClasses/ClassList")
)
const EduLogin = lazy(() => import("../../components/educator/Login/EduLogin"))
const PasswordComp = lazy(
  () => import("../../components/EducationUi/PasswordComp/PasswordComp")
)
const EduSignup = lazy(
  () => import("../../components/educator/Signup/EduSignup")
)
const EduSignupCongo = lazy(
  () => import("../../components/educator/Signup/congo/congo")
)
const EduReferSignup = lazy(
  () => import("../../components/educator/Signup/EduSignup")
)
const MeekoJourney = lazy(
  () => import("../../components/EducationUi/MeekoJourney/MeekoJourney")
)
const Finance = lazy(
  () => import("../../components/EducationUi/Finance/Finance")
)
const Growth = lazy(() => import("../../components/EducationUi/Growth/Growth"))
const Review_Rating = lazy(
  () => import("../../components/EducationUi/Review_Rating/Review_Rating")
)
const Video_Requests = lazy(
  () => import("../../components/EducationUi/Video_Requests/Video_Requests")
)
const Edu_HelpDesk = lazy(
  () => import("../../components/EducationUi/HelpDesk/HelpDesk")
)
const Edu_HelpTicket = lazy(
  () => import("../../components/EducationUi/HelpDesk/Ticket/Ticket")
)
const Ticket_Detail = lazy(
  () => import("../../components/EducationUi/HelpDesk/Ticket/Ticket_Detail")
)
const Edu_chat = lazy(
  () => import("../../components/EducationUi/Edu_chat/Educhat")
)
const WhiteBoard = lazy(
  () => import("../../components/liveClasses/liveClasses")
)
const UpgradePlans = lazy(
  () => import("../../components/EducationUi/UpgradePlans/UpgradePlans")
)
const PlanExpired = lazy(
  () => import("../../components/EducationUi/PlanExpired/PlanExpired")
)
const UpgradePlansSuccess = lazy(
  () => import("../../components/EducationUi/UpgradePlans/UpgradePlansSuccess")
)
const UpgradePlansFailure = lazy(
  () => import("../../components/EducationUi/UpgradePlans/UpgradePlansFailure")
)
const MobilePayment = lazy(
  () => import("../../components/MobilePayment/MobilePayment")
)
const UpcomingClasses = lazy(
  () => import("../../components/EducationUi/UpcomingClasses/UpcomingClasses")
)
const Reports = lazy(
  () => import("../../components/EducationUi/Reports/Reports")
)
const MeekoUsers = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoUsers")
)
// components/Policies/PoliciesRoutesContent/DISCLAIMER_POLICY_MOBILE
const DISCLAIMER_POLICY_MOBILE = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/DISCLAIMER_POLICY_MOBILE")
)

const CheckTestPaper = lazy(
  () => import("../../components/EducationUi/CheckTestPaper/CheckTestPaper")
)

const PlanDetail = lazy(
  () => import("../../components/educator/PlanDetail/PlanDetail")
)

const StudentsEnrolled = lazy(
  () => import("../../components/educator/StudentsEnrolled/StudentsEnrolled")
)

const EduVideo = lazy(
  () => import("../../components/educator/EduVideo/EduVideo")
)
const Demo = lazy(() => import("../../components/educator/Demo/Demo"))

const EducatorRoutes = () => {
  return (
    <>
      {/* Educator Routes */}

      <GuestRoute exact path="/educator/login" component={EduLogin} />
      <GuestRoute
        exact
        path="/educator/reset-password/:id"
        component={PasswordComp}
      />
      <GuestRoute exact path="/educator/signup" component={EduSignup} />
      <GuestRoute
        exact
        path="/educator-signup/:id"
        component={EduReferSignup}
      />

      <GuestRoute
        exact
        path="/educator/signup/congo"
        component={EduSignupCongo}
      />
      <GuestRoute
        exact
        path="/educator/policies/privacy-policy"
        component={DISCLAIMER_POLICY_MOBILE}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/demo/video"
        component={Demo}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/signup/steps"
        component={EduSteps}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/dashboard"
        component={Edu_Dashboard}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/class-reschedule/:id"
        component={Reschedule}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/calendar"
        component={Calendar}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/live-class"
        component={LiveClasses}
        liveClasslayout={false}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/live-class/:classId/:sessionId"
        component={LiveClasses}
        liveClasslayout={false}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/classes"
        component={EduClassList}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/mobile-classes"
        component={EduClassList}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/add"
        component={Edu_Create_Class}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/add-pre-recorded"
        component={Edu_Create_PreRecordedClass}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/free"
        component={FreeClass}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/edit"
        component={Edu_Create_Class}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/edit/:classId"
        component={Edu_Create_Class}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/edit/:classId/:tabName"
        component={Edu_Create_Class}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/classes/view/:classId"
        component={Edu_Create_Class}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/class-history"
        component={ClassHistory}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/students-enrolled"
        component={StudentsEnrolled}
        liveClasslayout={true}
      />

      {/* Educator's Create Test routes */}
      <EducatorPrivateRoute
        exact
        path="/educator/test"
        component={CreateTest}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/test/add"
        component={Edu_Create_Test}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/test/view"
        component={Edu_Create_Test}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/test/edit"
        component={Edu_Create_Test}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/education-profile"
        component={EducationProfile}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/my-space"
        component={MySpace}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/notification"
        component={NotificationPage}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/refer"
        component={ReferPage}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/refer-and-earn"
        component={NewReferPage}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/refer-and-earn-history"
        component={ReferEarnHistory}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/how-its-work"
        component={Howitwork}
        liveClasslayout={true}
      />
      {/* <EducatorPrivateRoute
        exact
        path="/educator/class-history"
        component={ClassHistory}
        liveClasslayout={true}
      /> */}
      <EducatorPrivateRoute
        exact
        path="/educator/class-history/attendance"
        component={ClassAttendance}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/meeko-journey"
        component={MeekoJourney}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/finance"
        component={Finance}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/growth"
        component={Growth}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/review-rating"
        component={Review_Rating}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/video-requests"
        component={Video_Requests}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/help-desk"
        component={Edu_HelpDesk}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/ticket"
        component={Edu_HelpTicket}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/ticket/:ticketId"
        component={Ticket_Detail}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/chat"
        component={Edu_chat}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/white-board"
        component={WhiteBoard}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/mobile-white-board"
        component={MobileLiveClasses}
        liveClasslayout={true}
        withLayout={false}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/upgrade-plans"
        component={UpgradePlans}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/subscriptionpackage/success"
        component={UpgradePlansSuccess}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/subscriptionpackage/failure"
        component={UpgradePlansFailure}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/expired-package"
        component={PlanExpired}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/mobile-payment"
        component={MobilePayment}
        liveClasslayout={false}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/upcoming-classes"
        component={UpcomingClasses}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/reports"
        component={Reports}
        liveClasslayout={true}
      />

      <EducatorPrivateRoute
        exact
        path="/educator/policies/terms-and-conditions-meeko-users"
        component={MeekoUsers}
        liveClasslayout={false}
      />
      
      <EducatorPrivateRoute
        exact
        path="/educator/test/check-test-paper/:childId/:testId/:classId/:batchId"
        component={CheckTestPaper}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/plan-detail/:planId"
        component={PlanDetail}
        liveClasslayout={true}
      />
      <EducatorPrivateRoute
        exact
        path="/educator/video-demo"
        component={EduVideo}
        liveClasslayout={true}
      />
    </>
  )
}

export default React.memo(EducatorRoutes)
