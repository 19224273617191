import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import { AuthReducer } from './auth/reducer';
import { AuthState } from './auth/types';

import { ChatReducer } from './chat/reducer';
import { ChatState } from './chat/types';

import { NotificationReducer } from './notification/reducer';
import { NotificationState } from './notification/types';

import { DashboardReducer } from './dashboard/reducer';
import { DashboardState } from './dashboard/types';
import { SpaceshipReducer } from './spaceship/reducer';
import { SpaceshipState } from './spaceship/types';
import { ParentDashboardReducer } from './parent/child/reducer';
import { ParentDashboardState } from './parent/child/type';
import { EducatorClassReducer } from './educatorClass/reducer';
import { EducatorClassState } from './educatorClass/type';
import { DashboardVideoReducer } from './dashboardvideo/reducer';

// import { ProfileState } from './profile/types';
// import { ProfileReducer } from './profile/reducer';

export interface ApplicationState {
  auth: AuthState;
  chat: ChatState;
  layout: DashboardState;
  router: RouterState;
  ships: SpaceshipState;
  child: ParentDashboardState;
  eduClass: EducatorClassState;
  video: string;
  notification: NotificationState;
  // profileDetail: ProfileState
}

export const createRootReducer = (history: History) =>
  combineReducers({
    auth: AuthReducer,
    chat: ChatReducer,
    layout: DashboardReducer,
    ships: SpaceshipReducer,
    child: ParentDashboardReducer,
    video: DashboardVideoReducer,
    eduClass: EducatorClassReducer,
    notification: NotificationReducer,
    router: connectRouter(history),
  });
