import { Reducer } from 'redux';

import { ChatActionTypes, ChatState } from './types';
import { chatTimeWithGroup } from '../../helpers/helpFunctions'
import moment from 'moment';
 
export const initialState: ChatState = {
    errors: undefined,
    loading: false,
    authenticated: false,
    elearnerreceiverlist: [],
    currentEduList: {},
    chatDetailList: {
        child: {},
        collections: [],
        educator: {},
        parent: {},
        _id: ""
    },
    chatMessageGrpList: [],
    convertedChatMessageGrp: [],
    currentELearnerList: {},
    currentEduRevList: {}
};

const reducer: Reducer<ChatState> = (state = initialState, action) => {
    switch (action.type) {
        case ChatActionTypes.SET_ELEARNERRECEIVERLIST_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: undefined,
            };
        }
        case ChatActionTypes.SET_ELEARNERRECEIVERLIST: {
            // console.log(action.payload, '---------in action')
            return {
              ...state,
              loading: false,
              elearnerreceiverlist: action.payload,
              errors: undefined,
            };
        }
        case ChatActionTypes.SET_ELEARNERRECEIVERLIST_ERROR: {
            return {
              ...state,
              loading: false,
              errors: action.payload,
            };
        }
        case ChatActionTypes.SET_CURRENTEDUCHATLIST_REQUEST: {
            return {
                ...state,
                loading: true,
                errors: undefined,
            };
        }
        case ChatActionTypes.SET_CURRENTEDUCHATLIST: {
            // console.log(action.payload, '---------in action')
            return {
              ...state,
              loading: false,
              currentEduList: action.payload,
              errors: undefined,
            };
        }
        case ChatActionTypes.SET_CURRENTEDUCHATLIST_ERROR: {
            return {
              ...state,
              loading: false,
              errors: action.payload,
            };
        }
        case ChatActionTypes.SET_CHATDETAILS: {
            let chatData: any = []
            let groupArrays: any = []
            chatData = action.payload.collections
            const groups = chatData.reduce((groups, game) => {
                let date = chatTimeWithGroup(game.date.split('T')[0])
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            // Edit: to add it in the array format instead
            groupArrays = Object.keys(groups).map((date) => {
                return {
                    date,
                    chatData: groups[date]
                };
            });
            return {
              ...state,
              loading: false,
              chatDetailList: action.payload,
              chatMessageGrpList: groupArrays,
              errors: undefined,
            };
        }
        case ChatActionTypes.SET_ELEARNERSENDMESSAGE: {
            let data: any = JSON.parse(JSON.stringify(state.chatDetailList))
            let convertedChatMessageGrpList: any = []
            if(data.collections && data.collections.length > 0){
                data.collections.unshift(action?.payload?.chat)
                convertedChatMessageGrpList = JSON.parse(JSON.stringify(state.convertedChatMessageGrp))
                if(moment(action?.payload?.chat?.date).format("DD/MM/YYYY") === moment(data.collections[0].date).format("DD/MM/YYYY")){
                    convertedChatMessageGrpList[0].chatData.unshift(action?.payload?.chat)
                } else {
                    const groups = data.collections.reduce((groups, game) => {
                        let date = chatTimeWithGroup(game.date.split('T')[0])
                        if (!groups[date]) {
                            groups[date] = [];
                        }
                        groups[date].push(game);
                        return groups;
                    }, {});

                    // Edit: to add it in the array format instead
                    convertedChatMessageGrpList = Object.keys(groups).map((date) => {
                        return {
                            date,
                            chatData: groups[date]
                        };
                    });
                }
            } else {
                data.collections.push(action?.payload?.chat)
                const groups = data.collections.reduce((groups, game) => {
                    let date = chatTimeWithGroup(game.date.split('T')[0])
                    if (!groups[date]) {
                        groups[date] = [];
                    }
                    groups[date].push(game);
                    return groups;
                }, {});

                // Edit: to add it in the array format instead
                convertedChatMessageGrpList = Object.keys(groups).map((date) => {
                    return {
                        date,
                        chatData: groups[date]
                    };
                });
            }
            return {
              ...state,
              loading: false,
              chatDetailList: data,
              chatMessageGrpList: data.collections,//groupArrays,
              convertedChatMessageGrp: convertedChatMessageGrpList,
              errors: undefined,
            };
        }
        case ChatActionTypes.SET_CHATDETAILEMPTY: {
            return {
                ...state,
                loading: false,
                chatDetailList: {
                    child: {},
                    collections: [],
                    educator: {},
                    parent: {},
                    _id: ""
                },
                chatMessageGrpList: [],
                convertedChatMessageGrp: []
            };
        }
        case ChatActionTypes.SET_EDUCATORRECEIVERLIST: {
            // console.log(action.payload, '---------SET_EDUCATORRECEIVERLIST in action')
            return {
                ...state,
                loading: false,
                currentELearnerList: action.payload,
                errors: undefined,
            }
        }
        case ChatActionTypes.SET_CURRENTELEARNERRECEIVERLIST: {
            // console.log(action.payload, '---------in action')
            let convertedChatMessageGrpList: any = []
            const groups = action.payload.collections.reduce((groups, game) => {
                let date = chatTimeWithGroup(game.date.split('T')[0])
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            // Edit: to add it in the array format instead
            convertedChatMessageGrpList = Object.keys(groups).map((date) => {
                return {
                    date,
                    chatData: groups[date]
                };
            });
            return {
                ...state,
                loading: false,
                chatDetailList: action.payload,
                chatMessageGrpList: action.collections,//groupArrays,
                convertedChatMessageGrp: convertedChatMessageGrpList,
                currentEduRevList: action.payload.educator,
                errors: undefined,
              };
        }
        case ChatActionTypes.SET_CURRENTEDUCATORRECEIVERLIST: {
            // console.log(action.payload, '---------in action')
            let convertedChatMessageGrpList: any = []
            const groups = action.payload.collections.reduce((groups, game) => {
                let date = chatTimeWithGroup(game.date.split('T')[0])
                if (!groups[date]) {
                    groups[date] = [];
                }
                groups[date].push(game);
                return groups;
            }, {});

            // Edit: to add it in the array format instead
            convertedChatMessageGrpList = Object.keys(groups).map((date) => {
                return {
                    date,
                    chatData: groups[date]
                };
            });
            return {
              ...state,
              loading: false,
              chatDetailList: action.payload,
              chatMessageGrpList: action.collections,//groupArrays,
              convertedChatMessageGrp: convertedChatMessageGrpList,
              currentEduRevList: action.payload.child,
              errors: undefined,
            };
        }
        default: {
            return state;
        }
    }
}

export { reducer as ChatReducer };       