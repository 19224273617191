import React, { Suspense, Fragment, useState, useEffect } from "react"
import api, { ENDPOINT, decryptAPIRes, socket } from "../../helpers/api"
import { useHistory } from "react-router"
import Loader from "../../components/common/SiteLoader/Loader"
import ParentHeader from "../../components/parent/layout/Header/ParentHeader"
import ParentFooter from "../../components/parent/layout/Footer/Footer"
import Tour from "reactour"
import axios, { AxiosRequestConfig } from "axios"
import { setChatDetailEmpty } from "../../store/chat/action"
import useWindowSize from "../../components/dashboard/useWindowSize"

const ParentLayout = ({ children }) => {
  const { width, height }: any = useWindowSize()
  useEffect(() => {
  }, [height])
  const [isTourOpen, setIsTourOpen]: any = useState(
    localStorage.getItem("websiteTour")
  )
  const history = useHistory()

  useEffect(() => {
    if (history?.location?.pathname != "/parent/chat") {
      setChatDetailEmpty()
      socket.emit("closechatpanel", localStorage.getItem("token"))
    }
  }, [history])

  useEffect(() => {
    const token: any = localStorage.getItem("selectedChildToken")
    const role: any = localStorage.getItem("role")
    if (
      history.location.pathname !== "/parent/dashboard" &&
      history.location.pathname !== "/parent/profile" &&
      history.location.pathname !== "/parent/help-desk" &&
      history.location.pathname !== "/parent/add-child" &&
      localStorage.token &&
      localStorage.role
    ) {
      try {
        if (token) {
          const config: AxiosRequestConfig = {
            method: "get",
            url: ENDPOINT + "/auth/check",
            headers: {
              "x-authorization": `${token}`,
            },
          }
          axios(config)
            .then(function (response) {
              var payload = decryptAPIRes(response.data.data)
              if (payload.code === 200) {
              } else {
                if (
                  history.location.pathname.includes(
                    "/parent/explore-classes/"
                  ) ||
                  history.location.pathname.includes(
                    "/parent/procced-to-pay/"
                  ) ||
                  history.location.pathname.includes("/parent/plan-detail/")
                ) {
                } else {
                  history.push("/parent/expired-package")
                }
              }
            })
            .catch(function (error) {
              var data = decryptAPIRes(error)
              console.log("error", data)
            })
        }
      } catch (err) {
        console.log("err", err)
      }
    }
  }, [])
  useEffect(() => {
    setIsTourOpen(localStorage.getItem("websiteTour"))
  }, [localStorage.getItem("websiteTour")])

  const closeTour = async () => {
    try {
      if (isTourOpen) {
        const payload = await api.get("/parent/websitetour")
        if (payload.status === 200) {
          localStorage.setItem("websiteTour", "false")
        }
        setIsTourOpen(false)
      }
    } catch (err) {
      console.log("error", err)
    }
  }

  const tourConfig = [
    {
      selector: '[data-tut="reactour__letsstart"]',
      content: "Site tour, let's start",
    },
    {
      selector: '[data-tut="reactour__Explore_Classes"]',
      content: "Browse classes for your child here",
    },
    {
      selector: '[data-tut="reactour__Payment_History"]',
      content: "See all your payment transactions here",
    },
    {
      selector: '[data-tut="reactour__Help_Desk"]',
      content: "If you have any queries or questions go to Help Desk",
    },
    {
      selector: '[data-tut="reactour__Child_Photo"]',
      content:
        "Tap on your child’s photo and review their details on the dashboard",
    },
    {
      selector: '[data-tut="reactour__Next_Class"]',
      content: "See your child’s next class here",
    },
    {
      selector: '[data-tut="reactour__Class_Overview"]',
      content: "See completed, ongoing, and upcoming classes of your child",
    },
    {
      selector: '[data-tut="reactour__Class_Recommendations"]',
      content: "See class recommendations according to your child’s interest",
    },
    {
      selector: '[data-tut="reactour__Notebook"]',
      content: "See all the materials provided in your child’s class here",
    },
    {
      selector: '[data-tut="reactour__Achievements"]',
      content: "See your child’s achievements here’",
    },
    {
      selector: '[data-tut="reactour__Calendar"]',
      content: "See your child’s class schedule here",
    },
    {
      selector: '[data-tut="reactour__Class_History"]',
      content: "See your child’s classes all details here",
    },
    {
      selector: '[data-tut="reactour__Childs_Profile"]',
      content: "Update your child’s profile details here",
    },
    {
      selector: '[data-tut="reactour__Meekoins"]',
      content: "Buy your child meekoins and see different packages here",
    },
  ]

  return (
    <Fragment>
      <Suspense fallback={<Loader />}>
        {width > 1025 && (
          <Tour
            onRequestClose={closeTour}
            steps={tourConfig}
            isOpen={isTourOpen == "true" ? true : false}
            maskClassName="mask"
            className="helper"
            rounded={5}
            accentColor="#5cb7b7"
            disableDotsNavigation={true}
            disableInteraction={true}
            lastStepNextButton={
              <button className="completeTour" onClick={() => closeTour()}>
                Done! Let's start learning
              </button>
            }
          />
        )}
        {!children.props.liveClasslayout ? (
          <div
            className="edu-dashboard-banner"
            style={{
              minHeight: `${height}px`,
            }}
          >
            <ParentHeader />
            {children}

            <ParentFooter />
          </div>
        ) : (
          <>{children}</>
        )}
      </Suspense>
    </Fragment>
  )
}

export default React.memo(ParentLayout)
