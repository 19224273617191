import ParentPrivateRoute from "./ParentPrivateRoute"
import { lazy } from "react"
import { BrowserRouter as Router } from "react-router-dom"
import GuestRoute from "../GuestRoute"
import ClassDetail from "../../components/ClassDetail/ClassDetail"
import PrivateRoute from "../E-leanerRoutes/PrivateRoute"
import React from "react"

const ParentDashboard = lazy(
  () => import("../../components/parent/dashboard/dashboard")
)
const ParentChild = lazy(
  () => import("../../components/parent/child/addChild/addchild")
)
const ParentPackages = lazy(
  () => import("../../components/parent/child/packages/packages")
)
const ParentPayment = lazy(
  () => import("../../components/parent/child/payment/payment")
)
const parentNoteBook = lazy(
  () =>
    import("../../components/parent/dashboard/parentNoteBook/parentNoteBook")
)
const parentSaveClasses = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentSaveClasses/parentSaveClasses"
    )
)
const parentMeekoinsPackages = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentMeekoinsPackages/parentMeekoinsPackages"
    )
)
const parentCalendar = lazy(
  () =>
    import("../../components/parent/dashboard/parentCalendar/parentCalendar")
)
const parentAchivement = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentAchivements/parentAchivement"
    )
)
const parentExploreClasses = lazy(
  () =>
    import("../../components/parent/parentExploreClasses/parentExploreClasses")
)
const ParentExploreSubject = lazy(
  () =>
    import(
      "../../components/parent/parentExploreClasses/parentExploreSubject/parentExploreSubject"
    )
)
const ParentPreRecordedSubject = lazy(
  () =>
    import(
      "../../components/parent/parentExploreClasses/parentPreRecordedSubject/parentPreRecordedSubject"
    )
)
const ParentExploreClassCard = lazy(
  () =>
    import(
      "../../components/parent/parentExploreClasses/parentExploreClassCard/parentExploreClassCard"
    )
)
const ParentPreRecordedClassCard = lazy(
  () =>
    import(
      "../../components/parent/parentExploreClasses/ParentPreRecordedClassCard/ParentPreRecordedClassCard"
    )
)
const ReferEarnHistory = lazy(
  () => import("../../components/parent/ReferEarnHistory/ReferEarnHistory")
)
const ParentLogin = lazy(() => import("../../components/parent/login/login"))
const PasswordComp = lazy(
  () => import("../../components/parent/PasswordComp/PasswordComp")
)
const ParentSignup = lazy(() => import("../../components/parent/signup/signup"))

const ParentNewSignup = lazy(
  () => import("../../components/parent/signup/ParentNewSignup")
)

const PaymentHistory = lazy(
  () => import("../../components/parent/PaymentHistory/PaymentHistory")
)
const EducatorProfile = lazy(
  () => import("../../components/educator/Profile/Profile")
)
const ParentProfile = lazy(
  () => import("../../components/parent/Profile/Profile")
)
const ParentRefer = lazy(
  () => import("../../components/parent/ParentRefer/ParentRefer")
)
const NewParentRefer = lazy(
  () => import("../../components/parent/NewParentRefer/NewParentRefer")
)
const Nochild = lazy(() => import("../../components/parent/Nochild/Nochild"))
const ParentHelpDesk = lazy(
  () => import("../../components/parent/HelpDesk/HelpDesk")
)
const HelpTickets = lazy(
  () => import("../../components/parent/HelpDesk/Tickets/Tickets")
)
const HelpTicketsRefund = lazy(
  () => import("../../components/parent/HelpDesk/Tickets/Refund")
)
const Ticket_Detail = lazy(
  () => import("../../components/parent/HelpDesk/Tickets/Ticket_Detail")
)
const meekoinsSuccess = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentMeekoinsPackages/MeekoinsSuccess"
    )
)
const meekoinsFailure = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentMeekoinsPackages/MeekoinsFailure"
    )
)
const Thankyou = lazy(() => import("../../components/Thankyou/Thankyou"))
const Failure = lazy(() => import("../../components/Failure/Failure"))
// const parentChatBox = lazy(() => import("../../components/parent/dashboard/ChatBox/ChatBox"));
const parentChatBox = lazy(
  () => import("../../components/parent/dashboard/ChatBox/ChatBox")
)
const parentClassBookingPayment = lazy(
  () =>
    import("../../components/parent/ClassBookingPayment/ClassBookingPayment")
)
const Expiredpackage = lazy(
  () => import("../../components/parent/Expiredpackage/Expiredpackage")
)
const ChildAddedSuccess = lazy(
  () => import("../../components/parent/child/ChildAdded/Success")
)
const ChildAddedFailure = lazy(
  () => import("../../components/parent/child/ChildAdded/Failure")
)
const ChildPlanDetail = lazy(
  () => import("../../components/parent/child/ChildPlanDetail/ChildPlanDetail")
)
const ChildTopUpSuccess = lazy(
  () => import("../../components/parent/child/TopUpSuccess/TopUpSuccess")
)
const ChildTopUpFailure = lazy(
  () => import("../../components/parent/child/TopUpFailure/TopUpFailure")
)
const ParentPreviewTestResult = lazy(
  () =>
    import("../../components/parent/child/PreviewTestResult/PreviewTestResult")
)
const WhiteBoard = lazy(
  () => import("../../components/liveClasses/liveClasses")
)
const MobilePayment = lazy(
  () => import("../../components/MobilePayment/MobilePayment")
)
const GuestCompare = lazy(() => import("../../components/Compare/GuestCompare"))
const DISCLAIMERPOLICY = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/DISCLAIMER POLICY")
)
const policyteacheruser = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/policyteacheruser")
)
const MeekoTeacher = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoTeacher")
)
const MeekoUsers = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoUsers")
)
const AboutUs = lazy(() => import("../../components/AboutUs/AboutUs"))
const Blog = lazy(() => import("../../components/Blog/Blog"))
const BlogReadmore = lazy(() => import("../../components/Blog/Readmore"))
const PaymentDetail = lazy(
  () => import("../../components/parent/PaymentDetail/PaymentDetail")
)
const MeekoinDetail = lazy(
  () => import("../../components/parent/MeekoinDetail/MeekoinDetail")
)
const Notifications = lazy(
  () => import("../../components/parent/Notifications/Notifications")
)
const TopupDetail = lazy(
  () => import("../../components/TopupDetail/TopupDetail")
)
const PlanDetail = lazy(() => import("../../components/PlanDetail/PlanDetail"))

const ParentRoutes = () => {
  return (
    <>
      {/* Parents Routes */}

      <GuestRoute exact path="/parent/login" component={ParentLogin} />
      <GuestRoute
        exact
        path="/parent/reset-password/:id"
        component={PasswordComp}
      />
      {/* <GuestRoute exact path="/parent/signup" component={ParentSignup} /> */}
      <GuestRoute exact path="/parent/signup" component={ParentNewSignup} />
      {/* <ParentPrivateRoute
        exact
        path="/classdetail/:classId"
        component={ClassDetail}
      /> */}
      <ParentPrivateRoute exact path="/parent/thank-you" component={Thankyou} />
      <ParentPrivateRoute exact path="/parent/failure" component={Failure} />

      <ParentPrivateRoute
        exact
        path="/parent/dashboard"
        component={ParentDashboard}
        child={true}
      />

      <ParentPrivateRoute
        exact
        path="/parent/add-child"
        component={ParentChild}
      />
      <ParentPrivateRoute
        exact
        path="/parent/package"
        component={ParentPackages}
      />
      <ParentPrivateRoute
        exact
        path="/parent/payment"
        component={ParentPayment}
      />
      <ParentPrivateRoute
        exact
        path="/parent/notebook"
        component={parentNoteBook}
      />
      <ParentPrivateRoute
        exact
        path="/parent/save-classes"
        component={parentSaveClasses}
        child={true}
      />
      <ParentPrivateRoute
        exact
        path="/parent/MeekoinsPackages"
        component={parentMeekoinsPackages}
      />
      <ParentPrivateRoute
        exact
        path="/parent/calendar"
        component={parentCalendar}
      />
      <ParentPrivateRoute
        exact
        path="/parent/achivement"
        component={parentAchivement}
      />
      <ParentPrivateRoute
        exact
        path="/parent/explore-classes"
        component={parentExploreClasses}
      />
      <ParentPrivateRoute
        exact
        path="/parent/classes/:slug"
        component={ParentExploreClassCard}
      />
      <ParentPrivateRoute
        exact
        path="/parent/course/:id"
        component={ParentExploreSubject}
      />
      <ParentPrivateRoute
        exact
        path="/parent/online-classes/:filterBy"
        component={ParentExploreSubject}
      />
      <ParentPrivateRoute
        exact
        path="/parent/online-classes/search/:search"
        component={ParentExploreSubject}
      />

      <ParentPrivateRoute
        exact
        path="/parent/online-classes/:filterBy/:eduId"
        component={ParentExploreSubject}
      />
      {/* Pre Recorded classes Route */}
      <ParentPrivateRoute
        exact
        path="/parent/pre-recorded-classes"
        component={ParentPreRecordedSubject}
      />
      <ParentPrivateRoute
        exact
        path="/parent/pre-recorded-explore-class/card/:id"
        component={ParentPreRecordedClassCard}
      />
      {/* Pre Recorded classes Route */}
      <ParentPrivateRoute
        exact
        path="/parent/payment-history"
        component={PaymentHistory}
      />
      <ParentPrivateRoute
        exact
        path="/parent/educator/profile/:id"
        component={EducatorProfile}
      />

      <ParentPrivateRoute
        exact
        path="/parent/profile"
        component={ParentProfile}
      />
      <ParentPrivateRoute exact path="/parent/no-child" component={Nochild} />
      <ParentPrivateRoute exact path="/parent/refer" component={ParentRefer} />
      <ParentPrivateRoute
        exact
        path="/parent/refer-and-earn"
        component={NewParentRefer}
      />

      <ParentPrivateRoute
        exact
        path="/parent/help-desk"
        component={ParentHelpDesk}
      />
      <ParentPrivateRoute
        exact
        path="/parent/tickets"
        component={HelpTickets}
      />
      <ParentPrivateRoute
        exact
        path="/parent/tickets/refund"
        component={HelpTicketsRefund}
      />
      <ParentPrivateRoute
        exact
        path="/parent/ticket/:ticketId"
        component={Ticket_Detail}
      />

      <ParentPrivateRoute
        exact
        path="/parent/meekoins/success"
        component={meekoinsSuccess}
      />
      <ParentPrivateRoute
        exact
        path="/parent/meekoins/failure"
        component={meekoinsFailure}
      />

      <ParentPrivateRoute exact path="/parent/chat" component={parentChatBox} />

      <ParentPrivateRoute
        exact
        path="/parent/mobile-classbooking-payment"
        component={parentClassBookingPayment}
        liveClasslayout={true}
      />
      <ParentPrivateRoute
        exact
        path="/parent/expired-package"
        component={Expiredpackage}
      />

      <ParentPrivateRoute
        exact
        path="/parent/procced-to-pay/:id"
        component={PaymentDetail}
      />

      <ParentPrivateRoute
        exact
        path="/parent/subscriptionpackage/success"
        component={ChildAddedSuccess}
      />
      <ParentPrivateRoute
        exact
        path="/parent/subscriptionpackage/failure"
        component={ChildAddedFailure}
      />

      <ParentPrivateRoute
        exact
        path="/parent/plan-detail"
        component={ChildPlanDetail}
      />

      <ParentPrivateRoute
        exact
        path="/parent/topup/success"
        component={ChildTopUpSuccess}
      />
      <ParentPrivateRoute
        exact
        path="/parent/topup/failure"
        component={ChildTopUpFailure}
      />
      <ParentPrivateRoute
        exact
        path="/parent/mobile-payment"
        component={MobilePayment}
      />
      <ParentPrivateRoute
        exact
        path="/parent/compare-classes"
        component={GuestCompare}
      />
      <ParentPrivateRoute
        exact
        path="/parent/preview-test-result/:id/:classId"
        component={ParentPreviewTestResult}
      />

      <ParentPrivateRoute
        exact
        path="/parent/white-board"
        component={WhiteBoard}
        liveClasslayout={true}
      />

      <ParentPrivateRoute
        exact
        path="/parent/policies/privacy-policy"
        component={DISCLAIMERPOLICY}
      />
      <ParentPrivateRoute
        exact
        path="/parent/policies/privacy-policy-teacher-user"
        component={policyteacheruser}
      />
      <ParentPrivateRoute
        exact
        path="/parent/policies/terms-and-conditions-meeko-teacher"
        component={MeekoTeacher}
      />
      <ParentPrivateRoute
        exact
        path="/parent/policies/terms-and-conditions-meeko-users"
        component={MeekoUsers}
      />
      <ParentPrivateRoute exact path="/parent/about-us" component={AboutUs} />
      <ParentPrivateRoute exact path="/parent/blog" component={Blog} />
      <ParentPrivateRoute
        exact
        path="/parent/blog/:title"
        component={BlogReadmore}
      />
      <ParentPrivateRoute
        exact
        path="/parent/meekoin-detail/:offerId"
        component={MeekoinDetail}
      />
      <ParentPrivateRoute
        exact
        path="/parent/notifications"
        component={Notifications}
      />
      <ParentPrivateRoute
        exact
        path="/parent/topup-detail/:type/:amount"
        component={TopupDetail}
      />
      <ParentPrivateRoute
        exact
        path="/parent/plan-detail/:packageId"
        component={PlanDetail}
      />
      <ParentPrivateRoute
        exact
        path="/parent/refer-and-earn-history"
        component={ReferEarnHistory}
      />
      {/* END Parents Routes */}
    </>
  )
}

export default React.memo(ParentRoutes)
