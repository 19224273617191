import React, { useState, useEffect } from "react";
import api, {
  ASSETS_HOST_URL,
  GITHUB_ASSETS_HOST_URL,
} from "../../../../helpers/api";
import { useHistory } from "react-router";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ApplicationState } from "../../../../store";
import { logoutUser } from "../../../../store/auth/action";
import {
  getNotification,
  setReadNotification,
  allReadNotification,
} from "../../../../helpers/socket";
import moment from "moment";
import "./ParentHeader.scss";

import Popup from "../../../layout/Comman_Popup/Popup";
import { NavLink } from "react-router-dom";

interface PropsFromDispatch {
  logoutUser: any;
  userData: any;
  loading: any;
  parentNotificationCounter: any;
  parentNotification: any;
}

const EduHeader = ({
  logoutUser,
  userData,
  loading,
  parentNotificationCounter,
  parentNotification,
}: PropsFromDispatch) => {
  const [sidebar, setSidebar] = useState(false);
  const [DropDownIsOpen, setDropDownIsOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [profile, setProfile]: any = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [notificationPannel, setNotificationPannel]: any = useState(false);
  const [notificationData, setNotificationData]: any = useState();
  const [notificationLoading, setNotificationLoading]: any = useState(false);
  const history = useHistory();
  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    getNotification(-1);
    window.addEventListener("resize", () => setWidth(window.innerWidth));
    if (userData) {
      if (userData && userData.profilePic) {
        localStorage.setItem("profile", userData.profilePic.path);
        setProfile(userData.profilePic.path);
      }
    }
    if (localStorage.getItem("profile")) {
      setProfile(localStorage.getItem("profile"));
    }
  }, []);

  useEffect(() => {
    setProfile(localStorage.getItem("profile"));
  }, [JSON.parse(JSON.stringify(localStorage.getItem("profile")))]);

  useEffect(() => {}, [parentNotificationCounter, parentNotification]);

  const openLiveClass = () => {
    history.push("/educator/live-class");
  };

  const openhelpdesk = () => {
    history.push("/parent/help-desk");
  };
  const redirectToPage = (name) => {
    history.push(`${name}`);
  };

  const ShowPopUpHandler = (index) => {
    setShowPopup(index);
  };

  const logoutHandler = () => {
    return setShowPopup(!showPopup);
  };

  const getAllNotification = async () => {
    if (!notificationPannel) {
      getNotification(-1);
    }
  };

  return (
    <>
      {width > 980 ? (
        <header>
          <div className="header-alignment">
            <div className="logo">
              <img
                src={
                  GITHUB_ASSETS_HOST_URL + `img/new-logo.png`
                  // NewLogo
                }
                alt="NewLogo"
                onClick={() => redirectToPage("/parent/dashboard")}
              />
            </div>
            <div className="menu">
              <ul>
                <NavLink to="/parent/dashboard">
                  <li>Home</li>
                </NavLink>

                <li
                  onClick={() => redirectToPage("/parent/explore-classes")}
                  data-tut="reactour__Explore_Classes"
                >
                  Explore Classes
                </li>
                <NavLink
                  to="/parent/payment-history"
                  className="payment_history"
                  data-tut="reactour__Payment_History"
                >
                  <li> Payment History</li>
                </NavLink>
              </ul>
              <div className="user-information-alignment">
                <div
                  className="round-design"
                  onClick={() => {
                    history.push("/parent/chat");
                  }}
                >
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL +
                      `img/Edu_Dashboard/parent-chat.png`
                    }
                    alt="parent-mesaage"
                    className="chat"
                  />
                </div>
                <div
                  className="round-design notification-section"
                  onClick={() => {
                    setNotificationPannel(!notificationPannel);
                    getAllNotification();
                  }}
                >
                  {parentNotificationCounter &&
                  parentNotificationCounter > 0 ? (
                    <div className="notification-count">
                      {parentNotificationCounter &&
                      parentNotificationCounter > 99
                        ? `99+`
                        : parentNotificationCounter}
                    </div>
                  ) : null}
                  <img
                    src={GITHUB_ASSETS_HOST_URL + `img/notification-img.png`}
                    alt="NotificationImage"
                  />
                </div>
                <div
                  className="Navbar_Single_Icon_DropDown"
                  onClick={() => {
                    setDropDownIsOpen((value) => !value);
                  }}
                >
                  <div className="Profile">
                    <img
                      src={profile ? ASSETS_HOST_URL + profile : ""}
                      alt="Profile"
                    />
                  </div>
                  <div className="DropDownIconContainer">
                    <img
                      src={
                        GITHUB_ASSETS_HOST_URL +
                        `img/Edu_Dashboard/Dropdown.svg`
                      }
                      alt="DropDown"
                      className="DropDownIcon"
                    />
                  </div>
                  {DropDownIsOpen && (
                    <>
                      <div className="dropdown_Main_Container">
                        <div
                          onClick={() => {
                            history.push("/parent/profile");
                          }}
                        >
                          <img
                            src={GITHUB_ASSETS_HOST_URL + `img/user-icon.png`}
                            alt="Profile"
                          />
                          Profile
                        </div>
                        <div
                          onClick={() => {
                            history.push("/parent/dashboard");
                            localStorage.setItem("websiteTour", "true");
                          }}
                        >
                          <img
                            src={
                              GITHUB_ASSETS_HOST_URL +
                              `img/Educator_Icon/1 (1).png`
                            }
                            className="how-it-works"
                            alt="Logout"
                          />
                          How it work
                        </div>
                        {/* <div
                          onClick={() => {
                            history.push("/parent/refer-and-earn")
                          }}
                        >
                          <img
                            src={
                              GITHUB_ASSETS_HOST_URL +
                              `img/Educator_Icon/1 (1).png`
                            }
                            className="how-it-works"
                            alt="Logout"
                          />
                          Refer & Earn
                        </div> */}
                        <div
                          onClick={() => {
                            history.push("/parent/refer-and-earn-history");
                          }}
                        >
                          <img
                            src={
                              GITHUB_ASSETS_HOST_URL +
                              `img/Educator_Icon/1 (1).png`
                            }
                            className="how-it-works"
                            alt="Logout"
                          />
                          Refer & Earn History
                        </div>
                        <div
                          onClick={() => {
                            logoutHandler();
                          }}
                        >
                          <img
                            src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                            alt="Logout"
                          />
                          Logout
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div
                  className="round-design"
                  onClick={() => {
                    openhelpdesk();
                  }}
                  data-tut="reactour__Help_Desk"
                >
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL +
                      `img/parent/question-mark-icon.png`
                    }
                    alt="NotificationImage"
                  />
                </div>
              </div>
            </div>
          </div>
          <Popup
            PopupShown={showPopup}
            ActionToParent={ShowPopUpHandler}
            eventHandler={logoutUser}
            Message={"Are you sure you want to logout?"}
          />
          {notificationPannel && (
            <div className="notification-wrapper">
              <div className="notification-section">
                <div className="mark-view-all-section">
                  <div
                    className="mark-all-as-read"
                    onClick={() => {
                      if (
                        parentNotificationCounter &&
                        parentNotificationCounter > 0
                      ) {
                        allReadNotification();
                      }
                    }}
                  >
                    Read All
                  </div>
                  <NavLink to="/parent/notifications" className="view-all">
                    View All
                  </NavLink>
                </div>
                {!loading &&
                parentNotification &&
                parentNotification.length > 0 ? (
                  parentNotification.map((notification, index) => {
                    if (index <= 4) {
                      let notificationTxt = notification?.message;
                      if (notification?.isDynamic) {
                        if (notificationTxt.search("{{time}}") > -1) {
                          let replaceTime = notification?.dynamicDate?.filter(
                            (nTime, index) => nTime.name == "time"
                          );
                          if (replaceTime?.length > 0) {
                            notificationTxt = notificationTxt.replace(
                              "{{time}}",
                              moment(replaceTime[0].date)
                                .local()
                                .format("hh:mm a")
                            );
                          }
                          let replaceDate = notification?.dynamicDate?.filter(
                            (nTime, index) => nTime.name == "date"
                          );
                          if (replaceDate?.length > 0) {
                            notificationTxt = notificationTxt.replace(
                              "{{date}}",
                              moment(replaceTime[0].date)
                                .local()
                                .format("DD/MM/YYYY")
                            );
                          }
                        }
                      }
                      return (
                        <div
                          className="notification"
                          key={`notification_${index}`}
                          style={{
                            backgroundColor: !notification?.read
                              ? "#eff0f6"
                              : "",
                          }}
                          onClick={() => {
                            if (!notification.read) {
                              setReadNotification(notification?._id);
                            }
                          }}
                        >
                          <div className="notification-txt">
                            <img
                              src={
                                notification?.profilePic
                                  ? ASSETS_HOST_URL + notification?.profilePic
                                  : GITHUB_ASSETS_HOST_URL + `img/User.png`
                              }
                              className="notificatim-img"
                            />
                            <p>{notificationTxt}</p>
                          </div>
                          <p className="notification-date">
                            {moment(notification?.date)
                              .local()
                              .format("DD, MMM YYYY")}
                          </p>
                        </div>
                      );
                    }
                  })
                ) : loading ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  <div className="no-notification-section">
                    <p>No Notification For You</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </header>
      ) : (
        <header>
          <div className="header-alignment">
            <div className="showMenu" onClick={showSidebar}>
              <img
                src={GITHUB_ASSETS_HOST_URL + `img/Responsive/blue-menu.png`}
                alt="menu"
                className="_Responsive_Img"
              />
            </div>

            <div className="menu">
              <div
                className={
                  sidebar ? "nav-menu-educator active" : "nav-menu-educator"
                }
              >
                <li className="navbar-toggle" onClick={showSidebar}>
                  <i className="fa fa fa-times"></i>
                </li>

                <ul onClick={showSidebar}>
                  <NavLink to="/parent/dashboard">
                    <li>Home</li>
                  </NavLink>

                  <li
                    onClick={() => redirectToPage("/parent/explore-classes")}
                    data-tut="reactour__Explore_Classes"
                  >
                    Explore Classes
                  </li>

                  <NavLink
                    to="/parent/payment-history"
                    className="payment_history"
                    data-tut="reactour__Payment_History"
                  >
                    <li> Payment History</li>
                  </NavLink>
                </ul>
              </div>
            </div>

            <div className="logo">
              <img
                src={
                  // NewLogo
                  GITHUB_ASSETS_HOST_URL + `img/new-logo.png`
                }
                alt="NewLogo"
                onClick={() => redirectToPage("/parent/dashboard")}
              />
            </div>

            <div className="user-information-alignment">
              <div
                className="round-design responsive"
                onClick={() => {
                  history.push("/parent/chat");
                }}
              >
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/parentMessage.png`}
                  alt="Chat"
                  style={{ width: "60px" }}
                />
              </div>
              {/* <div className="round-design responsive">
                <img src={
                  // NotificationImage
                  GITHUB_ASSETS_HOST_URL + `img/notification-img.png`
                } alt="NotificationImage" />
              </div> */}
              <div
                className="round-design responsive notification-section"
                onClick={() => {
                  setNotificationPannel(!notificationPannel);
                  getAllNotification();
                }}
              >
                {parentNotificationCounter && parentNotificationCounter > 0 ? (
                  <div className="notification-count">
                    {parentNotificationCounter && parentNotificationCounter > 99
                      ? `99+`
                      : parentNotificationCounter}
                  </div>
                ) : null}
                <img
                  src={GITHUB_ASSETS_HOST_URL + `img/notification-img.png`}
                  alt="NotificationImage"
                />
              </div>
              <div
                className="Navbar_Single_Icon_DropDown"
                onClick={() => {
                  setDropDownIsOpen((value) => !value);
                }}
              >
                <div className="Profile">
                  <img
                    src={profile ? ASSETS_HOST_URL + profile : ""}
                    alt="Profile"
                  />
                </div>
                <div className="DropDownIconContainer">
                  <img
                    src={
                      GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/Dropdown.svg`
                    }
                    alt="DropDown"
                    className="DropDownIcon"
                  />
                </div>

                {DropDownIsOpen && (
                  <>
                    <div className="dropdown_Main_Container">
                      <div
                        onClick={() => {
                          history.push("/parent/profile");
                        }}
                      >
                        <img
                          src={GITHUB_ASSETS_HOST_URL + `img/user-icon.png`}
                          alt="Profile"
                        />
                        Profile
                      </div>
                      <div
                        onClick={() => {
                          history.push("/parent/refer-and-earn-history");
                        }}
                      >
                        <img
                          src={
                            GITHUB_ASSETS_HOST_URL +
                            `img/Educator_Icon/1 (1).png`
                          }
                          alt="Profile"
                        />
                        Refer & Earn History
                      </div>
                      <div
                        onClick={() => {
                          logoutHandler();
                        }}
                      >
                        <img
                          src={GITHUB_ASSETS_HOST_URL + `img/logout_icon.png`}
                          alt="Logout"
                        />
                        Logout
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className="round-design responsive"
                onClick={() => {
                  openhelpdesk();
                }}
                data-tut="reactour__Help_Desk"
              >
                <img
                  src={
                    // QuestionMark
                    GITHUB_ASSETS_HOST_URL + `img/parent/question-mark-icon.png`
                  }
                  alt="NotificationImage"
                />
              </div>
            </div>
          </div>
          <Popup
            PopupShown={showPopup}
            ActionToParent={ShowPopUpHandler}
            eventHandler={logoutUser}
            Message={"Are you sure you want to logout?"}
          />
        </header>
      )}
    </>
  );
};

const mapStateToProps = ({ auth, notification }: ApplicationState) => ({
  userData: auth.userData,
  loading: notification.loading,
  parentNotificationCounter: notification.parentNotificationCounter,
  parentNotification: notification.parentNotification,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    logoutUser: async () => {
      await dispatch(logoutUser());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EduHeader);
