import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { connect } from 'react-redux';
// import Header from '../components/layout/header/header';
// import LiveClassHeader from '../components/layout/liveClassHeader/liveClassHeader';
import '../index.scss';
import ParentLayout from './ParentLayout';

interface MyRouteProps extends RouteProps {
    component: any;
    authenticated: boolean;
    role: string;
    liveClasslayout?: boolean;
    child?: boolean;
    rest?: any;
}

const ParentPrivateRoute: React.FC<MyRouteProps> = ({ component: Component, authenticated, role, liveClasslayout, child, ...rest }: any) => (
    <>
        <Route {...rest} render={(props) =>
        (authenticated && role === "Parent" ?
            <ParentLayout {...props} >
                <Component {...props} liveClasslayout={liveClasslayout} child={child} />
            </ParentLayout>
            : <Redirect to='/' />)} />
    </>
);

const mapStateToProps = (state: any) => ({
    authenticated: state.auth.authenticated,
    role: state.auth.role
});

export default connect(mapStateToProps)(ParentPrivateRoute);
