import { Reducer } from 'redux';

import { AuthActionTypes, AuthState } from './types';

export const initialState: AuthState = {
  errors: undefined,
  loading: false,
  authenticated: false,
  role: "",
  profile: {},
  userData: {}
};

const reducer: Reducer<AuthState> = (state = initialState, action) => {
  switch (action.type) {
    case AuthActionTypes.AUTH_REQUEST: {
      return {
        ...state,
        loading: true,
        errors: undefined,
      };
    }
    case AuthActionTypes.AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
        errors: undefined,
      };
    }
    case AuthActionTypes.AUTH_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    }
    case AuthActionTypes.SET_AUTHENTICATED: {
      return {
        ...state,
        authenticated: true,
        role: action.payload.role,
        profile: action.payload?.details?.profilePic?.path,
      };
    }
    case AuthActionTypes.SET_USER_PROFILE: {
      return {
        ...state,
        authenticated: true,
        role: action.payload.role,
        userData: action.payload.details,
      };
    }
    case AuthActionTypes.SET_UNAUTHENTICATED: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

export { reducer as AuthReducer };
