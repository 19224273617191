import { Reducer } from 'redux';

import { ParentDashboardActionTypes, ParentDashboardState, } from './type';

export const initialState: ParentDashboardState = {
    errors: undefined,
    childData: {},
    loadingLayouts: true
};

const reducer: Reducer<ParentDashboardState> = (state = initialState, action) => {
    switch (action.type) {
        case ParentDashboardActionTypes.CHILD_LAYOUT_REQUEST: {
            return { ...state, loadingLayouts: true, errors: undefined };
        }
        case ParentDashboardActionTypes.CHILD_LAYOUT_SUCCESS: {
            return {
                ...state,
                loadingLayouts: false,
                childData: action.payload,
            };
        }
        case ParentDashboardActionTypes.CHILD_LAYOUT_ERROR: {
            return { ...state, loadingLayouts: false };
        }

        default: {
            return state;
        }
    }
};

export { reducer as ParentDashboardReducer };
