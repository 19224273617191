import React from "react"
import { lazy } from "react"
import ClassDetail from "../../components/ClassDetail/ClassDetail"
import GuestRoute from "../GuestRoute"

const PrivateRoute = lazy(() => import("./PrivateRoute"))
const Login = lazy(() => import("../../components/auth/login/login"))
const PasswordComp = lazy(
  () => import("../../components/PassWordComp/PasswordComp")
)
const Profile = lazy(() => import("../../components/profile/profile"))
const LearnerPreRecorded = lazy(
  () => import("../../components/LearnerPreRecorded/LearnerPreRecorded")
)

const Dashboard = lazy(() => import("../../components/dashboard/dashboard"))
const SavedClass = lazy(() => import("../../components/SavedClass/SavedClass"))
const LiveClasses = lazy(
  () => import("../../components/liveClasses/liveClasses")
)
const WhiteBoard = lazy(
  () => import("../../components/liveClasses/liveClasses")
)

const MobileLiveClasses = lazy(
  () => import("../../components/liveClasses/MobileLiveClasses")
)

const ChatBox = lazy(() => import("../../components/Chat_Box/ChatBox"))
const Explore_Classes = lazy(
  () => import("../../components/Explore_Classes/Explore_Class")
)
const Explore_Classes_Subject = lazy(
  () => import("../../components/Explore_Classes/Components/ExploreSubject")
)
const Explore_Classes_Card = lazy(
  () => import("../../components/Explore_Classes/Componen2/Explore_Class_card")
)
const Calendar = lazy(() => import("../../components/calendar/MeekoCalendar"))
const Notification = lazy(
  () => import("../../components/Notification/Notification")
)

const Refer = lazy(() => import("../../components/Refer/Refer"))

const Notebook = lazy(() => import("../../components/Notebook/Notebook"))

// PRFOILE_COMPONENTS
const Account = lazy(
  () => import("../../components/profile/components/Account/Account")
)
const EduProfile = lazy(
  () =>
    import("../../components/profile/components/Educational_Profile/EduProfile")
)
const Subscriptions = lazy(
  () =>
    import("../../components/profile/components/Subscriptions/Subscriptions")
)
const Galaxy = lazy(
  () => import("../../components/profile/components/Galaxy/Galaxy")
)
const Journey = lazy(
  () => import("../../components/profile/components/Journey/Journey")
)
const Games = lazy(
  () => import("../../components/profile/components/Games/Games")
)
const Meekoins = lazy(  
  () => import("../../components/profile/components/Meekoins/Meekoins")
)
const MeekoinPackage = lazy(
  () => import("../../components/profile/components/Meekoins/Package")
)
const MeekoinDetail = lazy(
  () => import("../../components/profile/components/Meekoins/MeekoinDetail")
)
const Spacesship = lazy(
  () => import("../../components/profile/components/Spaceship/Spacesship")
)
const History = lazy(
  () => import("../../components/profile/components/history/History")
)
const Historytable = lazy(
  () => import("../../components/profile/components/history/Historytable")
)
const Avatar = lazy(
  () => import("../../components/profile/components/Avatar/Avatar")
)
const MyAchievement = lazy(
  () =>
    import("../../components/profile/components/MyAchievement/MyAchievement")
)

const EducatorProfile = lazy(
  () => import("../../components/educator/Profile/Profile")
)

const HelpDesk = lazy(
  () => import("../../components/profile/components/HelpDesk/HelpDesk")
)

const HelpTickets = lazy(
  () => import("../../components/profile/components/HelpDesk/Tickets/Tickets")
)

const HelpTicketsRefund = lazy(
  () => import("../../components/profile/components/HelpDesk/Tickets/Refund")
)

const Ticket_Detail = lazy(
  () =>
    import("../../components/profile/components/HelpDesk/Tickets/Ticket_Detail")
)

const HelpTicketsVideoReq = lazy(
  () => import("../../components/profile/components/HelpDesk/Tickets/VideoReq")
)

const HelpTicketsOther = lazy(
  () => import("../../components/profile/components/HelpDesk/Tickets/Other")
)

const ChildExpiredPackage = lazy(
  () => import("../../components/ChildExpiredPackage/ChildExpiredPackage")
)
const MobilePayment = lazy(
  () => import("../../components/MobilePayment/MobilePayment")
)
const Attendtest = lazy(() => import("../../components/Attendtest/Attendtest"))
const PreviewTestResult = lazy(
  () => import("../../components/PreviewTestResult/PreviewTestResult")
)

const GuestCompare = lazy(() => import("../../components/Compare/GuestCompare"))
const Thankyou = lazy(() => import("../../components/Thankyou/Thankyou"))
const Failure = lazy(() => import("../../components/Failure/Failure"))
const DISCLAIMERPOLICY = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/DISCLAIMER POLICY")
)
const policyteacheruser = lazy(
  () =>
    import("../../components/Policies/PoliciesRoutesContent/policyteacheruser")
)
const MeekoTeacher = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoTeacher")
)
const MeekoUsers = lazy(
  () => import("../../components/Policies/PoliciesRoutesContent/MeekoUsers")
)
const AboutUs = lazy(() => import("../../components/AboutUs/AboutUs"))
const Blog = lazy(() => import("../../components/Blog/Blog"))
const BlogReadmore = lazy(() => import("../../components/Blog/Readmore"))
const PlanDetail = lazy(() => import("../../components/PlanDetail/PlanDetail"))
const ChildAddedSuccess = lazy(
  () => import("../../components/parent/child/ChildAdded/Success")
)
const ChildAddedFailure = lazy(
  () => import("../../components/parent/child/ChildAdded/Failure")
)
const ChildTopUpSuccess = lazy(
  () => import("../../components/parent/child/TopUpSuccess/TopUpSuccess")
)
const ChildTopUpFailure = lazy(
  () => import("../../components/parent/child/TopUpFailure/TopUpFailure")
)
const TopupDetail = lazy(
  () => import("../../components/TopupDetail/TopupDetail")
)
const meekoinsSuccess = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentMeekoinsPackages/MeekoinsSuccess"
    )
)
const meekoinsFailure = lazy(
  () =>
    import(
      "../../components/parent/dashboard/parentMeekoinsPackages/MeekoinsFailure"
    )
)
const parentClassBookingPayment = lazy(
  () =>
    import("../../components/parent/ClassBookingPayment/ClassBookingPayment")
)
const ContactUS = lazy(() => import("../../components/ContactUs/ContactUS"))
const ELearnerRoutes = () => {
  return (
    <>
      {/* <PrivateRoute
        exact
        path="/classdetail/:classId"
        component={ClassDetail}
      /> */}
      <PrivateRoute exact path="/learner/contact-us" component={ContactUS} />
      {/* E-Learner Routes */}
      <GuestRoute exact path="/learner/login" component={Login} />
      <GuestRoute
        exact
        path="/learner/reset-password/:id"
        component={PasswordComp}
      />
      <PrivateRoute
        exact
        path="/dashboard"
        component={Dashboard}
        componentProps={{ mobileDash: false }}
      />
      <PrivateRoute
        exact
        path="/mobile-dash"
        componentProps={{ mobileDash: true }}
        component={Dashboard}
        withLayout={false}
      />
      <PrivateRoute
        exact
        path="/learner/mobile-classbooking-payment"
        component={parentClassBookingPayment}
        liveClasslayout={true}
      />
      <PrivateRoute exact path="/learner/help-desk" component={HelpDesk} />
      <PrivateRoute exact path="/learner/tickets" component={HelpTickets} />
      <PrivateRoute
        exact
        path="/learner/tickets/refund"
        component={HelpTicketsRefund}
      />
      <PrivateRoute exact path="/ticket/:ticketId" component={Ticket_Detail} />
      <PrivateRoute
        exact
        path="/learner/tickets/video-request"
        component={HelpTicketsVideoReq}
      />
      <PrivateRoute
        exact
        path="/learner/tickets/other"
        component={HelpTicketsOther}
      />
      <PrivateRoute exact path="/profile" component={Profile} />

      <PrivateRoute
        exact
        path="/explore-pre-recorded-classes"
        component={LearnerPreRecorded}
      />

      <PrivateRoute
        exact
        path="/live-class"
        component={LiveClasses}
        liveClasslayout={true}
      />
      <PrivateRoute
        exact
        path="/live-class/:classId/:sessionId"
        component={LiveClasses}
        liveClasslayout={true}
      />
      <PrivateRoute
        exact
        path="/white-board"
        component={WhiteBoard}
        liveClasslayout={true}
        componentProps={{ mobileDash: false }}
      />
      <PrivateRoute
        exact
        path="/mobile-white-board"
        component={MobileLiveClasses}
        liveClasslayout={true}
        componentProps={{ mobileDash: true }}
      />
      <PrivateRoute exact path="/calendar" component={Calendar} />
      <PrivateRoute exact path="/notification" component={Notification} />
      <PrivateRoute exact path="/refer" component={Refer} />
      <PrivateRoute exact path="/notebook" component={Notebook} />
      <PrivateRoute exact path="/chat" component={ChatBox} />
      <PrivateRoute exact path="/save-class" component={SavedClass} />
      <PrivateRoute exact path="/explore-classes" component={Explore_Classes} />
      {/* <PrivateRoute exact path="/profile/avatar" component={Avatar} /> */}
      <PrivateRoute exact path="/profile/account" component={Account} />
      <PrivateRoute exact path="/thank-you" component={Thankyou} />
      <PrivateRoute exact path="/failure" component={Failure} />
      <PrivateRoute
        exact
        path="/profile/education-profile"
        component={EduProfile}
      />
      <PrivateRoute
        exact
        path="/profile/subscriptions"
        component={Subscriptions}
      />
      <PrivateRoute
        exact
        path="/profile/my-achievement"
        component={MyAchievement}
      />
      <PrivateRoute
        exact
        path="/profile/space-ship"
        component={Spacesship}
        componentProps={{ mobileDash: false }}
      />
      <PrivateRoute
        exact
        path="/Profile/mobile-spaceship"
        componentProps={{ mobileDash: true }}
        component={Spacesship}
        withLayout={false}
      />
      <PrivateRoute
        exact
        path="/profile/meekoins-journey"
        component={Journey}
      />
      <PrivateRoute
        exact
        path="/profile/meekoins-games"
        component={Games}
      />
      <PrivateRoute exact path="/profile/meekoins" component={Meekoins} />
      <PrivateRoute
        exact
        path="/profile/meekoin-package"
        component={MeekoinPackage}
      />
      <PrivateRoute
        exact
        path="/profile/meekoin-detail/:offerId"
        component={MeekoinDetail}
      />
      <PrivateRoute
        exact
        path="/profile/meekoins/success"
        component={meekoinsSuccess}
      />
      <PrivateRoute
        exact
        path="/profile/meekoins/failure"
        component={meekoinsFailure}
      />

      <PrivateRoute exact path="/profile/class-history" component={History} />
      <PrivateRoute
        exact
        path="/profile/class-historys"
        component={Historytable}
      />
      <PrivateRoute
        exact
        path="/profile/avatar"
        component={Avatar}
        componentProps={{ mobileDash: false }}
      />
      <PrivateRoute
        exact
        path="/Profile/mobile-avatar"
        componentProps={{ mobileDash: true }}
        component={Avatar}
        withLayout={false}
      />

      <PrivateRoute
        exact
        path="/Profile/mobile-meekoJourney"
        componentProps={{ mobileDash: true }}
        component={Journey}
        withLayout={false}
      />

      <PrivateRoute
        path="/learner/classes/:id"
        component={Explore_Classes_Card}
      />
      <PrivateRoute
        path="/learner/course/:id"
        component={Explore_Classes_Subject}
      />
      <PrivateRoute
        exact
        path="/learner/online-classes/:filterBy"
        component={Explore_Classes_Subject}
      />
      <PrivateRoute
        exact
        path="/learner/online-classes/:filterBy/:eduId"
        component={Explore_Classes_Subject}
      />
      <PrivateRoute
        path="/explore-classes/search-text/:search"
        component={Explore_Classes_Subject}
      />
      <PrivateRoute
        exact
        path="/educator/profile/:id"
        component={EducatorProfile}
      />
      <PrivateRoute
        exact
        path="/expired-package"
        component={ChildExpiredPackage}
      />
      <PrivateRoute
        exact
        path="/mobile-payment"
        component={MobilePayment}
        componentProps={{ mobileDash: true }}
        withLayout={false}
      />
      <PrivateRoute
        exact
        path="/attend-test/:id/:classId"
        component={Attendtest}
      />
      <PrivateRoute
        exact
        path="/preview-test-result/:id/:classId"
        component={PreviewTestResult}
      />
      <PrivateRoute exact path="/compare-classes" component={GuestCompare} />

      <PrivateRoute
        exact
        path="/learner/policies/privacy-policy"
        component={DISCLAIMERPOLICY}
      />
      <PrivateRoute
        exact
        path="/learner/policies/privacy-policy-teacher-user"
        component={policyteacheruser}
      />
      <PrivateRoute
        exact
        path="/learner/policies/terms-and-conditions-meeko-teacher"
        component={MeekoTeacher}
      />
      <PrivateRoute
        exact
        path="/learner/policies/terms-and-conditions-meeko-users"
        component={MeekoUsers}
      />
      <PrivateRoute exact path="/learner/about-us" component={AboutUs} />
      <PrivateRoute exact path="/learner/blog" component={Blog} />
      <PrivateRoute
        exact
        path="/learner/blog/:title"
        component={BlogReadmore}
      />
      <PrivateRoute
        exact
        path="/plan-detail/:packageId"
        component={PlanDetail}
      />
      <PrivateRoute
        exact
        path="/subscriptionpackage/success"
        component={ChildAddedSuccess}
      />
      <PrivateRoute
        exact
        path="/subscriptionpackage/failure"
        component={ChildAddedFailure}
      />
      <PrivateRoute exact path="/topup/success" component={ChildTopUpSuccess} />
      <PrivateRoute exact path="/topup/failure" component={ChildTopUpFailure} />
      <PrivateRoute
        exact
        path="/topup-detail/:type/:amount"
        component={TopupDetail}
      />

      {/* END E-leaner Routes */}
    </>
  )
}

export default React.memo(ELearnerRoutes)
