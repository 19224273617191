import { useState } from "react"
import { useHistory } from "react-router"
import {
  ASSETS_HOST_URL,
  GITHUB_ASSETS_HOST_URL,
} from "../../../../helpers/api"
import { AnyAction } from "redux"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { ApplicationState } from "../../../../store"
import { getNotification } from "../../../../helpers/socket"
import Popup from "../../../layout/Comman_Popup/Popup"
import { logoutUser } from "../../../../store/auth/action"
import "./Footer.scss"

const Footer = ({ parentNotificationCounter }: any) => {
  const history = useHistory()

  const [showPopup, setShowPopup] = useState(false)

  const logoutHandler = () => {
    return setShowPopup(!showPopup)
  }
  const openhelpdesk = () => {
    history.push("/parent/help-desk")
  }
  const ShowPopUpHandler = (index) => {
    setShowPopup(index)
  }

  return (
    <>
      <div className="parent_footer_container">
        <div className="footer_content">
          <div className="user-information-alignment">
            <div
              className="round-design"
              onClick={() => {
                history.push("/parent/chat")
              }}
            >
              <img
                src={
                  GITHUB_ASSETS_HOST_URL + `img/Edu_Dashboard/parent-chat.png`
                }
                alt="parent-mesaage"
                className="chat"
              />
            </div>

            <div
              className="round-design notification-section"
              onClick={() => {
                history.push("/parent/notifications")
              }}
            >
              <div className="notification-count">
                {parentNotificationCounter && parentNotificationCounter > 99
                  ? `99+`
                  : parentNotificationCounter}
              </div>
              <img
                src={
                  GITHUB_ASSETS_HOST_URL + `img/notification-img.png`
                  // NotificationImage
                }
                alt="NotificationImage"
                className="notification"
              />
            </div>

            <div
              className="round-design"
              onClick={() => {
                openhelpdesk()
              }}
              data-tut="reactour__Help_Desk"
            >
              <img
                src={
                  GITHUB_ASSETS_HOST_URL + `img/parent/question-mark-icon.png`
                  // QuestionMark
                }
                alt="NotificationImage"
                className="question"
              />
            </div>
          </div>
          <Popup
            PopupShown={showPopup}
            ActionToParent={ShowPopUpHandler}
            eventHandler={logoutUser}
            Message={"Are you sure you want to logout?"}
          />
        </div>
      </div>
    </>
  )
}

// export default Footer;
const mapStateToProps = ({ notification }: ApplicationState) => ({
  parentNotificationCounter: notification.parentNotificationCounter,
})

export default connect(mapStateToProps, null)(Footer)
