import axios from 'axios';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { store } from '../index';
import { AuthActionTypes } from '../store/auth/types';
import socketIOClient from "socket.io-client";

enum ErrorTypes {
  'UNAUTHORIZED' = 401,
  'SERVER_ERROR' = 500,
  'BAD_REQUEST' = 400
}

export const FRONT_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const FRONT_MEETUBE = process.env.REACT_APP_MEETUBE_FRONT_URL;
export const FRONT_MEETUBE_ENDPOINT = process.env.REACT_APP_MEETUBE_HOST_URL;

export const ISLIVE = process.env.REACT_APP_ISLIVE;
export const REACT_APP_APP_URL = process.env.REACT_APP_APP_URL;
export const ENDPOINT = process.env.REACT_APP_API_URL;

export const GITHUB_ASSETS_HOST_URL = process.env.REACT_APP_ASSETS_PATH;

export const ASSETS_HOST_URL = process.env.REACT_APP_HOST_URL;
export const MERCHANT_ID = process.env.REACT_APP_MERCHANT_ID;
export const NODEL_ID = process.env.REACT_APP_NODEL_ID;
export const ACCESS_CODE_TEST = process.env.REACT_APP_ACCESS_CODE_TEST;
export const ACCESS_CODE_LIVE = process.env.REACT_APP_ACCESS_CODE_LIVE;
export const TEST_KEY = process.env.REACT_APP_TEST_KEY;
export const LIVE_KEY = process.env.REACT_APP_LIVE_KEY;
export const CCAVENUE_URL_TEST = process.env.REACT_APP_CCAVENUE_URL_TEST;
export const CCAVENUE_URL_LIVE = process.env.REACT_APP_CCAVENUE_URL_LIVE;
export const SOKET_END_POINT = process.env.REACT_APP_SOKET_END_POINT;
export const S3_ENDPOINT = process.env.REACT_APP_S3_ENDPOINT

// FOR SMS INTEGRATION
export const SMS_URL = process.env.REACT_APP_URL;
export const SMS_USERNAME = process.env.REACT_APP_USERNAME;
export const SMS_PASSWORD = process.env.REACT_APP_PASSWORD;
export const SMS_FROM = process.env.REACT_APP_FROM;

// export const ENDPOINT = 'http://165.232.185.152:8081';

export const crypto_password = 'XtjDjBL2Q5QIX1GVIzJk2nItU29RnTti';

export const socket: any = socketIOClient(SOKET_END_POINT || "", { 
  'reconnection': true,
  'reconnectionDelay': 500,
  'reconnectionAttempts': Infinity, 
  'transports': ['websocket'] 
});

const instance = axios.create({
  baseURL: ENDPOINT,
});


instance.defaults.headers.common['Content-Type'] = 'application/json';

// Add a request interceptor
instance.interceptors.request.use(function (config) {
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(config.data), crypto_password);
  config.data = {
    data: ciphertext.toString()
  };
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  let data = decryptAPIRes(response.data.data);
  response.data = data.data;
  if (response.data === undefined) {
    response.data = data;
  }
  return response;
}, function (error) {
  if (error?.response?.status === ErrorTypes.UNAUTHORIZED) {
    console.log('calldisconnect event called')
    socket.emit('calldisconnect')
    localStorage.removeItem('token');
    localStorage.clear();
    delete instance.defaults.headers.common['x-authorization'];
    store.dispatch({ type: AuthActionTypes.SET_UNAUTHENTICATED });
  }
  const ciphertext = CryptoJS.AES.decrypt(error?.response?.data?.data, crypto_password);
  var plaintext = ciphertext.toString(CryptoJS.enc.Utf8);
  const errorMessage = JSON.parse(plaintext)
  toast.error(`${errorMessage.message}`)
  return Promise.reject(error);
});

export const encryptAPIRes = (data): any => {
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), crypto_password);
  data.data = {
    data: ciphertext.toString()
  };
  return data;
}

export const decryptAPIRes = (res) => {
  var ciphertext = CryptoJS.AES.decrypt(res, crypto_password);
  return JSON.parse(ciphertext.toString(CryptoJS.enc.Utf8) || "{}");
}

export default instance;
