// import jwtDecode from 'jwt-decode';

import { store } from '../index';
import { AuthActionTypes } from '../store/auth/types';
// import {logoutUser} from '../store/auth/action'
import api from './api';
import { socketUpdate } from './socket'

export const CheckAuthentication = () => {
  if (localStorage.token && localStorage.role) {
    socketUpdate()
    // const decodedToken: any = jwtDecode(authToken);
    store.dispatch({ type: AuthActionTypes.SET_AUTHENTICATED, payload: { role: localStorage.role } });
    api.defaults.headers.common['x-authorization'] = localStorage.token;
  }
};
