import { Location } from "history"
import { Route } from "react-router-dom"
import { connect } from "react-redux"
import { FC, lazy, Suspense } from "react"
import {
  BrowserRouter as Router,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import "./test.scss"
import Loader from "../components/common/SiteLoader/Loader"
import ELearnerRoutes from "./E-leanerRoutes/ELeanerRoutes"
import EducatorRoutes from "./educatorRouter/EducatorRoutes"
import PoliciesRoutes from "./PoliciesRoutes/PoliciesRoutes"
import ParentRoutes from "./parentRouter/ParentRoutes"


const GuestRoute = lazy(() => import("./GuestRoute"))
const SiteAccessComponent = lazy(() => import("../components/access/access"))
const SiteAdminAccessComponent = lazy(
  () => import("../components/AdminAccess/AdminAccess")
)
const SiteParentAccessComponent = lazy(
  () => import("../components/ParentAccess/ParentAccess")
)
const Login = lazy(() => import("../components/auth/login/login"))
const Main = lazy(() => import("../components/main/main"))

// const LandingPage = lazy(() => import("../components/LandingPage/LandingPage"));
const Front = lazy(() => import("../components/NewFront/Front"))

const ErrorPage = lazy(
  () => import("../components/common/Error Page/ErrorPage")
)

// const TeachOnMeeko = lazy(
//   () => import("../components/TeachOnMeeko/TeachOnMeeko")
// );

const Newtechonmeeko = lazy(
  () => import("../components/New TeachonMeeko/Newtechonmeeko")
)
const HelpDesk = lazy(() => import("../components/HelpDesk/HelpDesk"))
const guest_Explore_Classes_Card = lazy(
  () =>
    import(
      "../components/GuestExploreClass/Components/GuestExploreClassCard/GuestExploreClassCard"
    )
)
const guestExploreClass = lazy(
  () => import("../components/GuestExploreClass/GuestExploreClass")
)
const GuestMeekoGames = lazy(
  () => import("../components/GuestMeekoGames/GuestMeekoGames")
)
const BusinessCard1 = lazy(
  () => import("../components/Cards/BusinessCard1")
)
const BusinessCard2 = lazy(
  () => import("../components/Cards/BusinessCard2")
)

const guestExploreSubject = lazy(
  () => import("../components/GuestExploreSubject/GuestExploreSubject")
)
const GuestEducatorProfile = lazy(
  () => import("../components/GuestEducatorProfile/GuestEducatorProfile")
)
const PrivacyPolicy = lazy(() => import("../components/Policies/Policies"))
const AboutUs = lazy(() => import("../components/AboutUs/AboutUs"))
const ContactUS = lazy(() => import("../components/ContactUs/ContactUS"))
const ParentChildSignup = lazy(
  () =>
    import("../components/Explore_Classes/ParentChildSignup/ParentChildSignup")
)
const Blog = lazy(() => import("../components/Blog/Blog"))
const LearnonMeeko = lazy(
  () => import("../components/LearnonMeeko/LearnonMeeko")
)
const Prerecoded = lazy(() => import("../components/Prerecoded/Prerecoded"))

const BlogReadmore = lazy(() => import("../components/Blog/Readmore"))
const GuestCompare = lazy(() => import("../components/Compare/GuestCompare"))
const ClassDetail = lazy(() => import("../components/ClassDetail/ClassDetail"))
const ReferSignup = lazy(() => import("../components/ReferSignup/ReferSignup"))
const preRecordedExploreSubject = lazy(
  () =>
    import(
      "../components/parent/parentExploreClasses/parentExploreSubject/parentExploreSubject"
    )
)
const preRecordedExploreClassCard = lazy(
  () =>
    import(
      "../components/GuestExploreClass/Components/PreRecordedExploreClassCard/PreRecordedExploreClassCard"
    )
)
const NewReferPage = lazy(
  () => import("../components/EducationUi/NewReferPage/NewReferPage")
)

const NewSchoolReferPage = lazy(
  () => import("../components/EducationUi/NewReferPage/NewSchoolReferPage")
)

interface SwitchProps {
  location: Location
  authenticated: boolean
  role: string
}

const Routes: FC<SwitchProps> = ({ location, authenticated, role }) => (
  <TransitionGroup>
    <CSSTransition
      key={location.pathname}
      classNames="fade"
      timeout={{ enter: 10000, exit: 200 }}
    >
      <Switch location={location}>
        <Suspense fallback={<Loader />}>
          <Router>
            <Route path="/classdetail/:classId" component={ClassDetail} />
            {/* <GuestRoute exact path="/" component={LandingPage} /> */}
            <GuestRoute exact path="/" component={Front} />

            {/* <GuestRoute path="/ErrorPage" component={ErrorPage} /> */}

            <Route path="/access" component={SiteAccessComponent} />
            <Route path="/admin/access" component={SiteAdminAccessComponent} />
            <Route
              path="/parent/access"
              component={SiteParentAccessComponent}
            />
            <GuestRoute
              path="/explore-class"
              exact
              component={guestExploreClass}
            />
            <GuestRoute
              path="/meeko-games"
              exact
              component={GuestMeekoGames}
            />
            {/* <GuestRoute
              exact
              path="/explore-class/card/:id"
              component={guest_Explore_Classes_Card}
            /> */}
            {!window.location.href.includes("?") && (
              <Redirect
                from="/:url*(/+)"
                to={
                  location.pathname.slice(-1) === "/"
                    ? location.pathname.slice(0, -1)
                    : location.pathname
                }
              />
            )}
            <GuestRoute
              exact
              path="/business-card/naini-mehta"
              component={BusinessCard1}
            />
              <GuestRoute
              exact
              path="/business-card/jay-shah"
              component={BusinessCard2}
            />

            <GuestRoute
              path="/classes/:slug"
              exact
              component={guest_Explore_Classes_Card}
            />
            <GuestRoute
              path="/course/:id"
              exact
              component={guestExploreSubject}
            />
            <GuestRoute
              path="/pre-recorded-classes/view-all-subject/:id"
              exact
              component={preRecordedExploreSubject}
            />
            <GuestRoute
              exact
              path="/pre-recorded-explore-class/card/:id"
              component={preRecordedExploreClassCard}
            />
            <GuestRoute
              exact
              path="/online-classes/search/:search"
              component={guestExploreSubject}
            />
            <GuestRoute
              exact
              path="/online-classes/:filterBy"
              component={guestExploreSubject}
            />
            <GuestRoute
              exact
              path="/online-classes/:filterBy/:eduId"
              component={guestExploreSubject}
            />
            {/* <GuestRoute exact path="/teach-on-meeko" component={TeachOnMeeko} /> */}
            <GuestRoute
              exact
              path="/earn-on-meeko"
              component={Newtechonmeeko}
            />
            <GuestRoute exact path="/help-desk" component={HelpDesk} />
            <GuestRoute exact path="/policies" component={PrivacyPolicy} />
            <GuestRoute exact path="/about-us" component={AboutUs} />

            <GuestRoute exact path="/contact-us" component={ContactUS} />

            <GuestRoute
              exact
              path="/parent-child-signup/:id/:classId"
              component={ParentChildSignup}
            />
            <GuestRoute exact path="/signup/:id" component={ReferSignup} />
            <GuestRoute
              exact
              path="/educator-profile/:id"
              component={GuestEducatorProfile}
            />
            <GuestRoute exact path="/learn-on-meeko" component={LearnonMeeko} />
            <GuestRoute
              exact
              path="/pre-recorded-classes"
              component={Prerecoded}
            />
            <GuestRoute exact path="/blog" component={Blog} />
            <GuestRoute exact path="/blog/:title" component={BlogReadmore} />
            <GuestRoute exact path="/compare" component={GuestCompare} />
            <GuestRoute
              exact
              path="/school/refer-and-earn/:id"
              component={NewSchoolReferPage}
            />
            <GuestRoute
              exact
              path="/educator/refer-and-earn/:id/:name"
              component={NewReferPage}
            />
            <GuestRoute
              exact
              path="/parent/refer-and-earn/:id/:name"
              component={NewReferPage}
            />
            <ELearnerRoutes />
            <EducatorRoutes />
            <PoliciesRoutes />
            <ParentRoutes />
            <Route exact path="/404" component={ErrorPage} />
            {/* <Route exact path="*" render={() => <Redirect to="/404" />} /> */}
          </Router>
        </Suspense>
      </Switch>
    </CSSTransition>
  </TransitionGroup>
)

const mapStateToProps = (state: any) => ({
  authenticated: state.auth.authenticated,
  role: state.auth.role,
})

export default withRouter(connect(mapStateToProps)(Routes))
