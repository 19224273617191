import { Reducer } from 'redux';

import { SpaceshipActionTypes,SpaceshipState } from './types';

export const initialState: SpaceshipState = {
  errors: undefined,
  spaceships:[],
  emblem: null,
  avatar: null,
  loadingSpaceship: true
};

const reducer: Reducer<SpaceshipState> = (state = initialState, action) => {
    // console.log("state ", state);
  switch (action.type) {
    case SpaceshipActionTypes.LAYOUT_SPACESHIP_REQUEST: {
      return { ...state, loadingSpaceship: true, errors: undefined };
    }
    case SpaceshipActionTypes.LAYOUT_SPACESHIP_SUCCESS: {
      return {
        ...state,
        loadingSpaceship: false,
        spaceships: [  ...state.spaceships, action.payload ]
      };
    }
    case SpaceshipActionTypes.LAYOUT_EMBLEM_SUCCESS: {
      return {
        ...state,
        loadingSpaceship: false,
        emblem: action.payload
      };
    }
    case SpaceshipActionTypes.LAYOUT_AVATAR_SUCCESS: {
      return {
        ...state,
        loadingSpaceship: false,
        avatar: action.payload
      };
    }
    case SpaceshipActionTypes.LAYOUT_SPACESHIP_ERROR: {
      return { ...state, loadingSpaceship: false };
    }
    default: {
      return state;
    }
  }
};

export { reducer as SpaceshipReducer };
