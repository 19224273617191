export enum AuthActionTypes {
  AUTH_REQUEST = '@@auth/AUTH_REQUEST',
  AUTH_SUCCESS = '@@auth/AUTH_SUCCESS',
  AUTH_ERROR = '@@auth/AUTH_ERROR',

  ADMIN_AUTH_REQUEST = '@@auth/ADMIN_AUTH_REQUEST',
  ADMIN_AUTH_ERROR = '@@auth/ADMIN_AUTH_ERROR',

  SET_AUTHENTICATED = '@@auth/SET_AUTHENTICATED',
  SET_UNAUTHENTICATED = '@@auth/SET_UNAUTHENTICATED',
  SET_USER_PROFILE = '@@auth/SET_USER_PROFILE',
}

export interface AuthState {
  readonly loading: boolean;
  readonly errors?: string;
  readonly authenticated: boolean;
  readonly role: string;
  readonly profile: object;
  readonly userData: object;
}
