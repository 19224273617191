export enum NotificationActionTypes {
    GET_ALL_NOTIFICATION_REQUEST = '@@notification/GET_ALL_NOTIFICATION_REQUEST',
    GET_ALL_NOTIFICATION_SUSSESS = '@@notification/GET_ALL_NOTIFICATION_SUSSESS',
    GET_ALL_NOTIFICATION_ERROR = '@@notification/GET_ALL_NOTIFICATION_ERROR',

    SET_ELEARNERNOTIFICATION_REQUEST = '@@notification/SET_ELEARNERNOTIFICATION_REQUEST',
    SET_ELEARNERNOTIFICATION = '@@notification/SET_ELEARNERNOTIFICATION',
    SET_ELEARNERNOTIFICATION_ERROR = '@@notification/SET_ELEARNERNOTIFICATION_ERROR',

    SET_ELEARNERNOTIFICATION_COUNTER = '@@notification/SET_ELEARNERNOTIFICATION_COUNTER',
    EDIT_ELEARNERNOTIFICATION_COUNTER = '@@notification/EDIT_ELEARNERNOTIFICATION_COUNTER',

    SET_PARENTNOTIFICATION_REQUEST = '@@notification/SET_PARENTNOTIFICATION_REQUEST',
    SET_PARENTNOTIFICATION_ERROR = '@@notification/SET_PARENTNOTIFICATION_ERROR',
    SET_PARENTNOTIFICATION = '@@notification/SET_PARENTNOTIFICATION',

    SET_PARENTNOTIFICATION_COUNTER = '@@notification/SET_PARENTNOTIFICATION_COUNTER',
    EDIT_PARENTNOTIFICATION_COUNTER = '@@notification/EDIT_PARENTNOTIFICATION_COUNTER',

    SET_EDUCATORNOTIFICATION_REQUEST = '@@notification/SET_EDUCATORNOTIFICATION_REQUEST',
    SET_EDUCATORNOTIFICATION = '@@notification/SET_EDUCATORNOTIFICATION',
    SET_EDUCATORNOTIFICATION_ERROR = '@@notification/SET_EDUCATORNOTIFICATION_ERROR',
    SET_EDUCATORNOTIFICATION_COUNTER = '@@notification/SET_EDUCATORNOTIFICATION_COUNTER',
    EDIT_EDUCATORNOTIFICATION_COUNTER = '@@notification/EDIT_EDUCATORNOTIFICATION_COUNTER',
}

export interface NotificationState {
    readonly loading: boolean;
    readonly errors?: string;
    readonly notifications: Object;
    readonly elearnerNotification: Object;
    readonly elearnerNotificationCounter: number;
    readonly parentNotification: Object;
    readonly parentNotificationCounter: number;
    readonly educatorNotification: Object;
    readonly educatorNotificationCounter: number;

}