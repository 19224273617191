export enum ChatActionTypes {
    SET_ELEARNERRECEIVERLIST_REQUEST = '@@chat/SET_ELEARNERRECEIVERLIST_REQUEST',
    SET_ELEARNERRECEIVERLIST = '@@chat/SET_ELEARNERRECEIVERLIST',
    SET_ELEARNERRECEIVERLIST_ERROR = '@@chat/SET_ELEARNERRECEIVERLIST_ERROR',

    SET_CURRENTEDUCHATLIST_REQUEST = '@@chat/SET_CURRENTEDUCHATLIST_REQUEST',
    SET_CURRENTEDUCHATLIST = '@@chat/SET_CURRENTEDUCHATLIST',
    SET_CURRENTEDUCHATLIST_ERROR = '@@chat/SET_CURRENTEDUCHATLIST_ERROR',

    SET_CHATDETAILS = '@@chat/SET_CHATDETAILS',

    SET_ELEARNERSENDMESSAGE_REQUEST = '@@chat/SET_ELEARNERSENDMESSAGE_REQUEST',
    SET_ELEARNERSENDMESSAGE = '@@chat/SET_ELEARNERSENDMESSAGE',
    SET_ELEARNERSENDMESSAGE_ERROR = '@@chat/SET_ELEARNERSENDMESSAGE_ERROR',


    SET_EDUCATORRECEIVERLIST = '@@chat/SET_EDUCATORRECEIVERLIST',
    SET_CURRENTEDUCATORRECEIVERLIST= '@@chat/SET_CURRENTEDUCATORRECEIVERLIST',

    SET_CURRENTELEARNERRECEIVERLIST = '@@chat/SET_CURRENTELEARNERRECEIVERLIST',

    SET_CHATDETAILEMPTY = '@@chat/SET_CHATDETAILEMPTY'
}

export interface ChatState {
    readonly loading: boolean;
    readonly errors?: string;
    readonly authenticated: boolean;
    readonly elearnerreceiverlist: Object;
    readonly currentEduList: Object;
    readonly chatDetailList: Object;
    readonly chatMessageGrpList: Object;
    readonly currentELearnerList: Object;
    readonly currentEduRevList: Object;
    readonly convertedChatMessageGrp: Object;
}