import React from "react";
import { GITHUB_ASSETS_HOST_URL } from "../../../helpers/api";
const Loader = () => (
  <div
    style={{
      backgroundColor: "#06aed3",
      position: "fixed",
      zIndex: "999",
      height: "100%",
      width: "100%",
      overflow: "show",
      margin: "auto",
      top: "0",
      left: "0",
      bottom: "0",
      right: "0",
    }}
  >
    <img
      src={GITHUB_ASSETS_HOST_URL + `loading.gif`}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  </div>
);

export default React.memo(Loader);
