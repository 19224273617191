import { Reducer } from "redux"
import { EducatorClassActionTypes, EducatorClassState } from "./type"

export const initialState: EducatorClassState = {
  loadingLayouts: false,
  errors: undefined,
  classDetail: {
    title: "",
    keyword: [],
    mainCategoryId: "",
    subCategoryId: "",
    images: [],
    video: {},
    ageMinimum: 2,
    ageMaximum: 15,
    classSize: 10,
    courseDescription: "",
    materialDescription: "",
    parentalGuidance: "",
    additionalNotes: "",
    price: "",
    requirementIcon: [],
    primaryLanguageId: "",
    languages: [],
    free: false,
    otherMainCategoryName: "",
    otherSubCategoryName: "",
  },
  testDetail: {
    classDetailId: "",
    classes: [],
    name: "",
    note: "",
    totalSecond: "",
    totalMarks: 1,
    passingMark: "",
    question: [],
    language: "",
    type: "",
    status: "",
    active: "",
  },
  classHistoryDetails: [],
  classDetails: [],
  testDetails: [],
  classMode: "create",
  testMode: "create",
  classAttendanceId: "",
  classAttendanceDetails: {},
  freeClassDetail: {
    title: "",
    keyword: [],
    mainCategoryId: "",
    subCategoryId: "",
    images: [],
    video: {},
    ageMinimum: 2,
    ageMaximum: 15,
    classSize: 10,
    courseDescription: "",
    materialDescription: "",
    parentalGuidance: "",
    additionalNotes: "",
    price: "",
    requirementIcon: [],
    primaryLanguageId: "",
    languages: [],
    free: false,
    otherMainCategoryName: "",
    otherSubCategoryName: "",
  },
  freeClassDetails: [],
  freeClassMode: "create",
}

const reducer: Reducer<EducatorClassState> = (state = initialState, action) => {
  switch (action.type) {
    case EducatorClassActionTypes.CLASS_MODE: {
      return { ...state, classMode: action.payload, errors: undefined }
    }
    case EducatorClassActionTypes.FREE_CLASS_MODE: {
      return { ...state, freeClassMode: action.payload, errors: undefined }
    }
    case EducatorClassActionTypes.SET_CLASS_REQUEST: {
      return {
        ...state,
        loadingLayouts: false,
        classDetail: action.payload,
      }
    }
    case EducatorClassActionTypes.SET_FREE_CLASS_REQUEST: {
      return {
        ...state,
        loadingLayouts: false,
        freeClassDetail: action.payload,
      }
    }
    case EducatorClassActionTypes.CLASS_ADD_REQUEST: {
      return {
        ...state,
        loadingLayouts: false,
        classDetail: action.payload,
      }
    }
    case EducatorClassActionTypes.CLASS_REQUEST: {
      return {
        ...state,
        loadingLayouts: true,
      }
    }
    case EducatorClassActionTypes.CLASS_SUCCESS: {
      return {
        ...state,
        loadingLayouts: false,
        classDetails: action.payload,
      }
    }
    case EducatorClassActionTypes.CLASS_ERROR: {
      return {
        ...state,
        loadingLayouts: false,
      }
    }
    case EducatorClassActionTypes.CLASS_EDIT_REQUEST: {
      return { ...state, loadingLayouts: false, classDetails: action.payload }
    }
    case EducatorClassActionTypes.CLEAR_CLASS_DATA_REQUEST: {
      return {
        ...state,
        loadingLayouts: false,
        classDetail: {
          title: "",
          keyword: [],
          mainCategoryId: "",
          subCategoryId: "",
          images: [],
          video: {},
          ageMinimum: 2,
          ageMaximum: 15,
          classSize: 10,
          courseDescription: "",
          materialDescription: "",
          parentalGuidance: "",
          additionalNotes: "",
          price: "",
          requirementIcon: [],
          primaryLanguageId: "",
          languages: [],
          free: false,
          otherMainCategoryName: "",
          otherSubCategoryName: "",
        },
        classDetails: [],
        classMode: "create",
        freeClassDetail: {
          title: "",
          keyword: [],
          mainCategoryId: "",
          subCategoryId: "",
          images: [],
          video: {},
          ageMinimum: 2,
          ageMaximum: 15,
          classSize: 10,
          courseDescription: "",
          materialDescription: "",
          parentalGuidance: "",
          additionalNotes: "",
          price: "",
          requirementIcon: [],
          primaryLanguageId: "",
          languages: [],
          free: false,
          otherMainCategoryName: "",
          otherSubCategoryName: "",
        },
        freeClassDetails: [],
        freeClassMode: "create",
      }
    }
    case EducatorClassActionTypes.TEST_MODE: {
      return { ...state, testMode: action.payload, errors: undefined }
    }
    case EducatorClassActionTypes.SET_TEST_REQUEST: {
      return {
        ...state,
        loadingLayouts: false,
        testDetail: action.payload,
      }
    }
    case EducatorClassActionTypes.TEST_SUCCESS: {
      return {
        ...state,
        loadingLayouts: false,
        testDetails: action.payload,
      }
    }
    case EducatorClassActionTypes.TEST_ERROR: {
      return {
        ...state,
        loadingLayouts: false,
        testDetails: action.payload,
      }
    }
    case EducatorClassActionTypes.CLEAR_TEST_DATA_REQUEST: {
      return {
        ...state,
        loadingLayout: false,
        testDetail: {
          classDetailId: "",
          classes: [],
          testName: "",
          note: "",
          totalSecond: "",
          totalMarks: 1,
          passingMark: "",
          question: [],
          language: "",
          queType: "",
          status: "",
        },
      }
    }
    case EducatorClassActionTypes.CLASS_HISTORY_SUCCESS: {
      return {
        ...state,
        loadingLayouts: false,
        classHistoryDetails: action.payload,
      }
    }
    case EducatorClassActionTypes.CLASS_HISTORY_ERROR: {
      return {
        ...state,
        loadingLayouts: false,
        classHistoryDetails: action.payload,
      }
    }
    case EducatorClassActionTypes.SET_CLASS_ATTENDANCE_ID: {
      return {
        ...state,
        loadingLayouts: false,
        classAttendanceId: action.payload,
      }
    }
    case EducatorClassActionTypes.CLASS_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        loadingLayouts: false,
        classAttendanceDetails: action.payload,
      }
    }
    case EducatorClassActionTypes.CLASS_ATTENDANCE_ERROR: {
      return {
        ...state,
        loadingLayouts: false,
        classAttendanceDetails: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

export { reducer as EducatorClassReducer }
