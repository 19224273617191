
export enum ParentDashboardActionTypes {
    CHILD_LAYOUT_REQUEST = '@@layouts/CHILD_REQUEST',
    CHILD_LAYOUT_SUCCESS = '@@layouts/CHILD_LAYOUT_SUCCESS',
    CHILD_LAYOUT_ERROR = '@@layouts/CHILD_LAYOUT_ERROR',
}

export interface ParentDashboardState {
    readonly childData: Object;
    readonly loadingLayouts: boolean;
    readonly errors?: string;
}
